import { useMyContext } from "../../contexts/StateHolder";
import LivePlayer1 from "./LivePlayer1";
import { useEffect, useState } from "react";
import settings from "../../configs/config_settings.json";
import { useCookies } from "react-cookie";
import { useMyContextFunctions } from "../../contexts/ContextFunctions";

const LiveVideoPlayer = (props) => {
    const { autoplay = false } = props;
    // Bring stateholders from context
    const { key, lcId = 196291301, organizationId, liveChannelPrivate, showPlayer, setShowPlayer } = useMyContext();
    // const [channelServiceId, setChannelServiceId] = useState(null);
    const [groupId, setGroupId] = useState(null);
    const [companyId, setCompanyId] = useState(null);

    const { redirectUserToLogin } = useMyContextFunctions();
    const [cookies] = useCookies("");

    // console.log("cookies=>", cookies);

    // console.log(organizationId, "LCID=>", lcId);

    useEffect(() => {
        if (organizationId) {
            setGroupId(settings.organization.groupId);
            // setChannelServiceId(settings.organization.channelServiceId);
            setCompanyId(settings.organization.companyId);
            /* 
    If showPlayer is hidden (first time) or wantedHidden (user closed the player),
    turn it to visible as first/new video has been chosen.

    If player is already visible and new one is chosen, make player hidden
    (it will be reactivated in next useEffect)
    */
            if (showPlayer === "hidden" || showPlayer === "wantedHidden") {
                setShowPlayer("visible");
            } else {
                setShowPlayer("hidden");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);
    useEffect(() => {
        // If showPlayer is hidden, turn it visible. If showPlayer is wantedHidden, keep it hidden.
        if (showPlayer === "hidden") {
            setShowPlayer("visible");
        }
    }, [showPlayer, setShowPlayer]);
    useEffect(() => {
        if (liveChannelPrivate) {
            !cookies.userData.userId && redirectUserToLogin("login");
        }
    }, [redirectUserToLogin, cookies.userData, liveChannelPrivate]);

    return key && lcId && companyId && showPlayer === "visible" ? (
        <div className="maxContainerPrimary">
            <LivePlayer1
                companyId={companyId}
                groupId={groupId} // Group of organization
                organizationId={organizationId} // Id of organization
                playerAutoStart={props.playerAutoStart}
                secretKey={key} // Secret key for organization
                channelServiceId={lcId} // channelServiceId for live video
                backRoute={props.backRoute} // Route, where back button takes user. If undefined, it takes automatically to previous route
                //key={assetId} // For react's re-rendering purposes
                userId={liveChannelPrivate ? cookies.userData.userId : 0}
                license={settings.organization.radiantPlayerLicense}
                setShowPlayer={setShowPlayer}
                autoplay={autoplay}
            />
        </div>
    ) : null;
};

export default LiveVideoPlayer;
