// import { useMyContext } from "../../contexts/StateHolder";
import { useEffect, useState } from "react";
import settings from "../../configs/config_settings.json";
// import { useCookies } from "react-cookie";
// import { useMyContextFunctions } from "../../contexts/ContextFunctions";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as classes from "./TVGuide.module.css";
import TVGuideChannelList from "./TVGuideChannelList";
import { getChannels } from "../../scripts/dataHandlers";
import TVGuideHeader from "./TVGuideHeader";

const organizationId = settings.organization.organizationId;

export default function TVGuideChannelData() {
  const [channels, setChannels] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  
  useEffect(() => {
    const fetchLiveChannelData = async () => {
      const res = await getChannels(organizationId);
      // console.log("tv guide channels list=>", res)
      setChannels(res?.data.channels);
    }

    fetchLiveChannelData()
  }, []);


  return (
    <div className={classes.TVGuideContainer}>
      <div className={classes.TVGuideHeadContainer}>
        <TVGuideHeader toShow={5} setFrom={setFrom} setTo={setTo} />
      </div>
      <div className={classes.TVGuideDataContainer}>
        <TVGuideChannelList
          settings={settings.components.tvGuide_01}
          channels={channels}
          from={from}
          to={to}
        />
      </div>
    </div>
  );
}
