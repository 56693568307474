import { useState, useEffect, useMemo } from "react";
import * as classes from "./ChannelPlayer.module.css";
import playLogo from "../../images/play-circle.png"
import { useMyContext } from "../../contexts/StateHolder";
import { useHistory } from "react-router-dom";

export default function EventInfoItem(props) {
    const { name, description, startTime, duration, setCurrentEventName, lastIndex, event} = props
    const [open, setOpen] = useState(false);
    const {setChosenItem} = useMyContext();
    const history = useHistory();
    
    let first = false;
    let showValue = false;
    let showPlayIcon = true;
    let percent = 100;

        // let start_Time = startTime.slice(11, 16);

        let st = useMemo(()=>{
            return new Date(startTime);
        },[startTime])
        
        st = st.getTime();
        let et = st + duration * 1000;

        // let end_Time = new Date(et).toISOString().slice(11, 16);

        let currentTime = useMemo(()=>{
            return new Date();
        },[])
        currentTime = currentTime.getTime();

        // Local time
        let stDate = new Date(st);
        let etDate = new Date(et);
        let stHours = "" + stDate.getHours();
        let stMins = "" + stDate.getMinutes();
        let etHours = "" + etDate.getHours();
        let etMins = "" + etDate.getMinutes();

        while (stHours.length < 2) {
            stHours = "0" + stHours;
        }
        while (stMins.length < 2) {
            stMins = "0" + stMins;
        }
        while (etHours.length < 2) {
            etHours = "0" + etHours;
        }
        while (etMins.length < 2) {
            etMins = "0" + etMins;
        }

        // console.log("stHours, stMins, etHours, etMins", stHours, stMins, etHours, etMins);

        let localStartTime = stHours + ":" + stMins;
        // let localEndTime = etHours + ":" + etMins;

        if (currentTime <= et) {
            showValue = true;
        }

    if (st <= currentTime && currentTime <= et) {
        first = true;
        percent = ((currentTime - st) / (duration * 1000)) * 100;
    }
    if(st>=currentTime){
        showPlayIcon = false
    }

    useEffect(() => {
        if (st <= currentTime && currentTime <= et) {
            setCurrentEventName(name);
            // setCurrentEventDesc(description);
        }
    },[currentTime, st, et, setCurrentEventName, description, name])
    
    function playButtonClickHandle(){
        console.log(event)
        setChosenItem(event)
        history.push(`/playCatchUpVideo?title=${props.event.description.en_US.name}&startTime=${props.event.info.startTime}&duration=${props.event.info.duration}&channelName=${props.channelName}`)
    }

    return (
        <td>
            {true && (
                <div className={first ? `${classes.FirstInfo} ${classes.TVGuideEventItemTitle} ${open && classes.tvGuideTitle}` : `${classes.TVGuideEventItemTitle} ${open && classes.tvGuideTitle} ${classes.EachInfo}`} onClick={() => setOpen(!open)}>
                    <div className={classes.TimeInfo}>
                    <div className={classes.unactiveTxT}>{localStartTime}</div>
                    </div>
                    {/* onClick={() => setOpen(!open)} */}
                    <div className={`${classes.ShowName} ${open && classes.textLong} ${classes.TVGuideEventItemText}`}>
                        {name}
                        {open && <p style={{color: "white"}}>{description}</p>}
                        {open && <div className={showPlayIcon? classes.watchReplay : classes.HideWatchReplay} onClick={() => playButtonClickHandle()}> 
                                    <img className={classes.PlayImg} src={playLogo} alt="" /><h4>Watch Replay</h4>
                                </div>
                        }
                    </div>
                    <div className={showPlayIcon? classes.PlayButton : classes.HideButton}>
                    {!open && <div onClick={() => playButtonClickHandle()} ><img src={playLogo} alt="" /></div>}
                    </div>
                    {/* {first && <p className={classes.activeTxT}>{localStartTime + "-" + localEndTime}</p>} */}
                  
                    {/* className={classes.FirstInfoBorder} style={{ width: `${percent}%` }} */}
                </div>
            )}
            {lastIndex && (
                <div className={classes.noProgram}>
                    <p>No programs available</p>
                </div>
            )}
        </td>
    );
}
