import "./App.css";
import NavBar from "./components/NavBars/NavBar";
// import CategoryContentSimiliar from "./components/ViewAssets/Sliders/CategoryContentSimiliar";
import DetailsVideo from "./components/Details/DetailsVideo";
//import DetailsSerie from './components/Details/DetailsSerie';
import FAQ from "./components/FAQ/FAQ";
import TOS from "./components/TOS/TOS";
//import ChosenCategoryGrid from './components/ViewAssets/Grids/ChosenCategoryGrid';

import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useMyContext } from "./contexts/StateHolder";
import BottomBar from "./components/Footers/BottomBar";
import Profile from "./components/Profile/Profile";
import settings from "./configs/config_settings.json";
import Banner from "./components/ViewAssets/Sliders/Banner";
// import Promo from "./components/ViewAssets/Sliders/Promo";
import Search from "./components/Search/Search";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import CategoriesWithTitles from "./components/ViewAssets/Sliders/CategoriesWithTitles";
import HomePageCategories from "./components/ViewAssets/Sliders/HomePageCategories";
import AllShowsPageCategories from "./components/ViewAssets/Sliders/AllShowsPageCategories";

//import CategoriesWithTitles from './components/ViewAssets/Sliders/CategoriesWithTitles';
import {
    // getAssets,
    getCategories,
    getChannelStatus,
} from "./scripts/dataHandlers";
//import RenderCategoryDropdown from './components/DropDowns/CategoryDropdown';
// import LoginBTN from "./components/Login/LoginBTN";
import NavBarButton from "./components/NavBars/NavBarButton";
import AssetVideoPlayer from "./components/VideoPlayer/AssetVideoPlayer";
import SignUpForm from "./components/SignUp/SignUpForm";
import LoginForm from "./components/Login/LoginForm";
import { useCookies } from "react-cookie";
import "./Shared/FontAwesomeIcon";
import ListAllCategories from "./components/Categories/ListAllCategories";
// import SignUpBar2 from "./components/SignUp/SignUpBar2";
import LiveVideoPlayer from "./components/VideoPlayer/LiveVideoPlayer";
import ChannelOne from "./components/LiveChannel/ChannelOne";
//import NavBarCategoryDropDown from './components/NavBars/NavBarCategoryDropdown';
import * as classes from "./components/NavBars/NavBar.module.css";
// import ListAllSeries from "./components/ViewAssets/Sliders/Series/ListAllSeries/ListAllSeries";
import ContactUs from "./components/ContactUs/ContactUs";

import EpisodesWithSerieTitles from "./components/ViewAssets/Sliders/Series/EpisodesWithSerieTitles/EpisodesWithSerieTitles";

//import DetailsSerieContainer from './components/Details/DetailsSerieContainer';

//import DetailsItemDescription from './components/Details/DetailsItemDescription';
import DetailsSeasonsAndEpisodes from "./components/Details/DetailsSeasonsAndEpisodes";
// new slick

import "react-multi-carousel/lib/styles.css";

// React spinner
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// toastify
import "react-toastify/dist/ReactToastify.css";

// Google Analytics
import ReactGA from "react-ga";
// import LanguageSelect1 from "./components/LanguageSelect/LanguageSelect1";
// import LanguageSelectMobile from "./components/LanguageSelect/LanguageSelectMobile";
import Socials from "./components/EmbedPlayer/EmbedPlayer";
import HelmetMetaData from "./components/ShareSocialMedia/HelmetMetaData";
// import { getImageByKey } from "./scripts/getImageByKey";

// import siteLogo from "./images/bannerdemo_image1.jpeg";
import TrendingTopShows from "./components/ViewAssets/Sliders/Series/TrendingTopShows/TrendingTopShows";

import SeriesAsset from "./components/ViewAssets/Sliders/Series/SeriesAsset/SeriesAsset";
import LiveChannels from "./components/ViewAssets/Sliders/Series/LiveChannels/LiveChannels";
// import { createToken } from "./scripts/utils";
import CookiesPolicy from "./components/CookiesPolicy/CookiesPolicy";
import TVGuide from "./components/TVGuide/TVGuide";
import ReactVideoPlayer from "./components/VideoPlayer/ReactVideoPlayer";
import AddPoster from "./components/AddPoster/AddPoster";
import MoviesList from "./components/Movie/MoviesList";
import Allshows from "./components/AllShows/AllShows";
import AllShowsListingPage from "./components/AllShowsListingPage/AllShowsListingPage";
//import TagManager from 'react-gtm-module'

if (settings.googleAnalytics !== "") {
    // Initialize Google analytics with API-key from .env file
    ReactGA.initialize(settings.googleAnalytics);
}

function App() {
    // Bring stateholders from context
    const {
        setAllCategories,

        key,
        setKey,

        organizationId,
        setOrganizationId,

        language,
        setLanguage,

        user,
        setUser,
        //liveChannelPrivate,
        setLiveChannelPrivate,
        //allLanguages,
        setAllLanguages,
        isResponsiveclose,
        chosenItem,
    } = useMyContext();

    const location = useLocation();

    const { t } = useTranslation();

    const [cookies] = useCookies("");
    const [loading, setLoading] = useState(true);
    const [seasonCount, setSeasonCount] = useState();
    const [episodeNumber, setEpisodeNumber] = useState();

    // UseEffect that will re-trigger on every pagechange, sending current location to Google Analytics
    useEffect(() => {
        if (settings.googleAnalytics !== "") {
            // Report Google Analytics about user entering this page
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [location]);

    /*
  // Google Tag Manager
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-P5GDF94' });
  }, []);
  */

    /**** STEP 1, FIRST TIME INIT PROGRAM ****/
    useEffect(() => {
        async function initProgram() {
            try {
                // Set organizationId to context
                setOrganizationId(settings.organization.organizationId);

                // Set key to context
                setKey(settings.organization.key);

                // If there's existing userToken in cookies
                if (cookies?.userData?.userToken) {
                    // Get user from context and copy it to modifiedUser
                    let modifiedUser = { ...user };

                    // Change status of user as logged in
                    modifiedUser.loggedIn = true;

                    // Set userToken from cookies
                    modifiedUser.userId = cookies?.userData?.userId;

                    // Set userToken from cookies
                    modifiedUser.userToken = cookies?.userData?.userToken;

                    // Update user in context, with modified data
                    setUser(modifiedUser);
                }

                // Set language from config and set it to context
                setLanguage(settings.language);

                setAllLanguages(Object.keys(settings.languages).map((k) => settings.languages[k]));
            } catch (err) {
                console.log(err);
            }
        }

        initProgram();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // channel status (private or public)

    // console.log(window.location.hostname);

    useEffect(() => {
        const fetchChannelStatus = async () => {
            const res = await getChannelStatus(settings.organization.organizationId, settings.organization.channelServiceId);
            // console.log(res, "channelStatus");
            // const token = createToken(
            //   settings.organization.organizationId,
            //   133968907,
            //   key
            // );

            // const res1 = await getAssets(organizationId, 133968907, token, language);
            // console.log(res1);
            if (res === 0) {
                // it meeans channeel is private and user neeeds to login
                setLiveChannelPrivate(true);
            } else {
                setLiveChannelPrivate(false);
            }
            setLoading(false);
        };

        if (organizationId) {
            fetchChannelStatus();
        }
    }, [organizationId, setLiveChannelPrivate, key, language]);

    /**** STEP 2, CHECK LANGUAGE AND GET CATEGORIES (STEP WILL BE REPEATED IF LANGUAGE CHANGES) ****/
    useEffect(() => {
        async function getAndSetCategories() {
            try {
                // Get categories from datahandler
                const categoryList = await getCategories(organizationId, key, language, user);

                // console.log("All Category List=>", categoryList)

                // Set categories in context
                setAllCategories(categoryList);
            } catch (err) {
                console.log(err);
            }
        }

        // Get categories only if language is set
        if (language !== "") {
            getAndSetCategories();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    return !loading ? (
        <div className="App">
            <div className="container">
                <Switch>
                    <Route path={`/${settings.routes.shareAsset}/vod/:orgId/:asset`} exact>
                        <Socials routes={settings.routes} />
                    </Route>
                    <Route>
                        <NavBar
                            links={
                                <>
                                    <div className={classes.navBarRow_primary}>
                                        <NavBarButton route={settings.routes.liveChannels} name={t("navBar.liveChannels")} />
                                        <NavBarButton route={settings.routes.tvGuide} name={t("navBar.tvGuide")} />

                                        <NavBarButton route={settings.routes.tvShows} name={t("navBar.tvShows")} />
                                    </div>
                                    <div className={classes.navRight}>
                                        {/* // show only in bigger screen */}
                                        {true && (
                                            <>
                                                <NavBarButton route={settings.routes.search} icon="FaSearch" />
                                            </>
                                        )}
                                    </div>
                                </>
                            }
                        />

                        {/* ROUTE FOR FRONT PAGE */}
                        <Route path="/" exact>
                            <div className="homeContainer">
                                <Banner settings={settings.components.frontPageHeroBanner_01} groupItemId="197443601" />
                                <div className="liveChannels liveOn">
                                    <LiveChannels
                                        settings={settings.components.frontPageCategories_03}
                                        showDuration={false}
                                        showReleaseYear={true}
                                        showPublishedDate={true}
                                        hideDescription={true}
                                        latestData={true}
                                        showTitle={false}
                                        titleName={t("seriesSlider.onnow")}
                                        showOnNowDetails={true}
                                    />
                                </div>
                                <div className="hero-container">
                                    {/* <SeriesAsset
                                        settings={settings.components.frontPageCategories_01}
                                        showDuration={false}
                                        showReleaseYear={false}
                                        showPublishedDate={true}
                                        showCategoryName={false}
                                        hideDescription={true}
                                        latestData={false}
                                        titleName={t("seriesSlider.trending")}
                                        showTitle={true}
                                        showSerieViewAll={true}
                                        imageType="thumbnail"
                                    /> */}
                                    <AddPoster />
                                    {/* <SeriesAsset
                                        settings={settings.components.frontPageCategories_01}
                                        showDuration={false}
                                        showReleaseYear={false}
                                        showPublishedDate={true}
                                        showCategoryName={false}
                                        hideDescription={true}
                                        latestData={false}
                                        titleName={t("seriesSlider.mytv")}
                                        showTitle={true}
                                        showSerieViewAll={false}
                                    /> */}
                                    <HomePageCategories
                                        settings={settings.components.frontPageCategories_02}
                                        showDuration={false}
                                        showReleaseYear={true}
                                        showPublishedDate={true}
                                        hideDescription={true}
                                        setEpisodeNumber={setEpisodeNumber}
                                    />

                                    <TrendingTopShows
                                        settings={settings.components.frontPageCategories_01}
                                        titleName={t("seriesSlider.trendingShows")}
                                        hideDescription={true}
                                        showCategoryName={true}
                                        showTitle={true}
                                        showSerieViewAll={false}
                                        showBanner={false}
                                    />
                                </div>
                            </div>
                        </Route>

                        {/* /* ROUTE FOR LIVE CHANNEL_current/ */}
                        <Route path={`/${settings.routes.liveChannels}/:orgId/:lcId`} exact>
                            <ChannelOne />
                            <div className="onnow-livechannel">
                                <SeriesAsset
                                    settings={settings.components.frontPageCategories_01}
                                    showDuration={false}
                                    showReleaseYear={false}
                                    showPublishedDate={true}
                                    showCategoryName={false}
                                    hideDescription={true}
                                    latestData={false}
                                    titleName={t("seriesSlider.trending")}
                                    showTitle={true}
                                    showSerieViewAll={true}
                                    imageType="thumbnail"
                                />
                            </div>
                        </Route>

                        {/* /* ROUTE FOR LIVE CHANNEL_current default/ */}
                        <Route path={`/${settings.routes.liveChannels}`} exact>
                            <ChannelOne />

                            <div className="onnow-livechannel">
                                <SeriesAsset
                                    settings={settings.components.frontPageCategories_01}
                                    showDuration={false}
                                    showReleaseYear={false}
                                    showPublishedDate={true}
                                    showCategoryName={false}
                                    hideDescription={true}
                                    latestData={false}
                                    titleName={t("seriesSlider.trending")}
                                    showTitle={true}
                                    showSerieViewAll={true}
                                    imageType="thumbnail"
                                />
                            </div>
                        </Route>

                        {/* /* ROUTE FOR TV Guide/ */}
                        <Route path={`/${settings.routes.tvGuide}`} exact>
                            <div className="TvGuideSection">
                                <TVGuide />
                            </div>
                        </Route>

                        <Route path={`/${settings.routes.tvShows}`} exact>
                            <Banner settings={settings.components.frontPageHeroBanner_01} groupItemId="196295801" />
                            <MoviesList settings={settings.components.frontPageHeroBanner_01} imageType="coverImage" />
                            <div className="MoviesSlider">
                                {/* <SeriesAsset
                                    settings={settings.components.frontPageCategories_01}
                                    showDuration={false}
                                    showReleaseYear={false}
                                    showPublishedDate={true}
                                    showCategoryName={false}
                                    hideDescription={true}
                                    latestData={false}
                                    titleName={t("seriesSlider.trending")}
                                    showTitle={true}
                                    showSerieViewAll={true}
                                /> */}
                                <TrendingTopShows
                                    settings={settings.components.frontPageCategories_01}
                                    titleName={"Trending Shows"}
                                    hideDescription={true}
                                    showCategoryName={true}
                                    showTitle={true}
                                    showSerieViewAll={false}
                                    showBanner={false}
                                />
                                <CategoriesWithTitles
                                    settings={settings.components.recommended}
                                    showDuration={false}
                                    showReleaseYear={true}
                                    showPublishedDate={true}
                                    hideDescription={true}
                                    setEpisodeNumber={setEpisodeNumber}
                                />
                                <HomePageCategories
                                    settings={settings.components.frontPageCategories_02}
                                    showDuration={false}
                                    showReleaseYear={true}
                                    showPublishedDate={true}
                                    hideDescription={true}
                                    setEpisodeNumber={setEpisodeNumber}
                                />
                            </div>
                        </Route>

                        <Route path={`/AllShows/Trending`} exact>
                            <ListAllCategories settings={settings.components.ListAllCategories_01} imageType="thumbnail" />
                        </Route>

                        <Route path={`/${settings.routes.tvShows}/categories/:title/:catID`} exact>
                            <ListAllCategories settings={settings.components.ListAllCategories_01} imageType="thumbnail" />
                        </Route>

                        <Route path={`/${settings.routes.playSerie}/:seriesId`} exact>
                            <AllShowsListingPage settings={settings.components.ListAllCategories_01} />
                            <TrendingTopShows
                                settings={settings.components.frontPageCategories_01}
                                titleName={t("seriesSlider.trendingShows")}
                                hideDescription={true}
                                showCategoryName={true}
                                showTitle={true}
                                showSerieViewAll={false}
                                showBanner={false}
                            />
                        </Route>

                        {/* ROUTE FOR VOD homepage */}
                        {/* <Route path="/mainVOD" exact>
              <div className="homeContainer">
                <Banner settings={settings.components.frontPageBanner_01} />

                <div className="hero-container">
                  <div className="latest-most-container">
                    <SeriesAsset
                      settings={settings.components.frontPageCategories_01}
                      showDuration={false}
                      showReleaseYear={true}
                      showPublishedDate={true}
                      hideDescription={true}
                      latestData={true}
                      titleName={t("seriesSlider.latest")}
                    />
                  </div>
                  <div className="latest-most-container">
                    <SeriesAsset
                      settings={settings.components.frontPageCategories_01}
                      showDuration={false}
                      showReleaseYear={true}
                      showPublishedDate={true}
                      hideDescription={true}
                      latestData={false}
                      titleName={t("seriesSlider.most")}
                    />
                  </div>

                  <CategoriesWithTitles
                    settings={settings.components.frontPageCategories_02}
                    showDuration={false}
                    showReleaseYear={true}
                    showPublishedDate={true}
                    hideDescription={true}
                  />
                </div>
              </div>
            </Route> */}

                        {/* /* ROUTE FOR LIVE CHANNEL/ */}
                        <Route path={`/${settings.routes.onlineTV}`} exact>
                            <LiveVideoPlayer />
                        </Route>

                        {/* ROUTE FOR PLAY VIDEO*/}
                        <Route path={`/${settings.routes.playVideo}/:orgId/:asset`} exact>
                            <AssetVideoPlayer backRoute={"/"} playerAutoStart={true} />

                            <DetailsVideo hideBanner={true} hideInfoData={true} showPublishedDate={true} hideShare={true} isTVGuideItem={true} />
                            <div className="playMovieSlider">
                                <SeriesAsset
                                    settings={settings.components.frontPageCategories_01}
                                    showDuration={false}
                                    showReleaseYear={false}
                                    showPublishedDate={true}
                                    showCategoryName={false}
                                    hideDescription={true}
                                    latestData={false}
                                    titleName={t("seriesSlider.trending")}
                                    showTitle={true}
                                    showSerieViewAll={false}
                                    isMovie={true}
                                />
                            </div>
                        </Route>

                        {/* ROUTE FOR PLAY SERIE*/}
                        <Route path={`/${settings.routes.playSerie}/:orgId/:asset/:serieId?/:seasonId?`}>
                            <AssetVideoPlayer backRoute={"hidden"} playerAutoStart={true} backButton={false} isSerie={true} />

                            <DetailsVideo
                                hideBanner={true}
                                //hideShare={true}
                                hideInfoData={true}
                                showPublishedDate={true}
                                seasonCount={seasonCount}
                                episodeNumber={episodeNumber}
                                isSerie={true}
                            />
                            <DetailsSeasonsAndEpisodes
                                playVideoRoute={settings.routes.playSerie}
                                setSeasonCount={setSeasonCount}
                                setEpisodeNumber={setEpisodeNumber}
                            />
                            <TrendingTopShows
                                settings={settings.components.frontPageCategories_01}
                                titleName={t("seriesSlider.trendingShows")}
                                hideDescription={true}
                                showCategoryName={true}
                                showTitle={true}
                                showSerieViewAll={false}
                                showBanner={false}
                            />
                        </Route>

                        <Route path={`/${settings.routes.informative}`} exact>
                            <div className="homeContainer1">
                                <EpisodesWithSerieTitles
                                    settings={settings.components.informative}
                                    showDuration={false}
                                    showReleaseYear={true}
                                    showPublishedDate={true}
                                />
                            </div>
                        </Route>

                        <Route path={`/${settings.routes.business}`} exact>
                            <div className="homeContainer1">
                                <EpisodesWithSerieTitles
                                    settings={settings.components.business}
                                    showDuration={false}
                                    showReleaseYear={true}
                                    showPublishedDate={true}
                                />
                            </div>
                        </Route>

                        <Route path={`/${settings.routes.entertainment}`} exact>
                            <div className="homeContainer1">
                                <EpisodesWithSerieTitles
                                    settings={settings.components.entertainment}
                                    showDuration={false}
                                    showReleaseYear={true}
                                    showPublishedDate={true}
                                />
                            </div>
                        </Route>

                        <Route path={`/${settings.routes.sports}`} exact>
                            <div className="homeContainer1">
                                <EpisodesWithSerieTitles
                                    settings={settings.components.sports}
                                    showDuration={false}
                                    showReleaseYear={true}
                                    showPublishedDate={true}
                                />
                            </div>
                        </Route>

                        {/* /* ROUTE FOR TV SHOWS/ */}

                        {/* Route for particular shows items */}
                        <Route path={`/tvShows/:showName/:showId`} exact>
                            {/* <ListAllCategories
                settings={settings.components.ListAllCategories_01}
                imageType="thumbnail"
              /> */}
                            <Allshows
                                settings={settings.components.episodesWithSerieTitles_02}
                                showDuration={false}
                                showReleaseYear={true}
                                showPublishedDate={false}
                                showTitle={true}
                                setEpisodeNumber={setEpisodeNumber}
                            />
                        </Route>

                        {/* ROUTE FOR SVOD SERIE CATEGORIES VIEW*/}
                        <Route path={`/${settings.routes.svodSeriesCategoriesRoute}/:orgId/:asset/:serieId?/:seasonId?`} exact>
                            {/* //hideCta hides play button */}
                            <DetailsVideo hideCta={true} hideInfoData={true} isSerieCategory={true} showPublishedDate={false} />
                            <DetailsSeasonsAndEpisodes playVideoRoute={settings.routes.playSerie} />
                        </Route>

                        {/* ROUTE FOR CATHCUPVIDEO */}
                        <Route path={`/${settings.routes.playCatchUpVideo}`} exact>
                            <ReactVideoPlayer showDetails={true} />
                            <DetailsVideo
                                hideBanner={true}
                                //hideShare={true}
                                hideInfoData={true}
                                showPublishedDate={true}
                                isSerie={false}
                                isCatchUpPlay={true}
                            />
                        </Route>

                        {/* ROUTE FOR PROFILE VIEW*/}

                        <Route path={`/${settings.routes.profile}`}>
                            <Profile settings={settings.components.profile} />
                        </Route>

                        {/* ROUTE FOR FAQ VIEW*/}
                        <Route path={`/${settings.routes.faq}`}>
                            <FAQ />
                        </Route>

                        {/* ROUTE FOR TOS VIEW*/}
                        <Route path={`/${settings.routes.tos}`}>
                            <TOS />
                        </Route>

                        {/* ROUTE FOR COOKIES POLICY VIEW*/}
                        <Route path={`/${settings.routes.cookiesPolicy}`}>
                            <CookiesPolicy />
                        </Route>

                        {/* ROUTE FOR SEARCH VIEW*/}
                        <Route path={`/${settings.routes.search}`}>
                            <Search settings={settings.components.search_01} />
                        </Route>

                        {/* ROUTE FOR LOGIN VIEW*/}

                        <Route path={`/${settings.routes.login}`}>
                            <LoginForm routes={settings.routes} />
                        </Route>

                        {/* ROUTE FOR SIGNUP VIEW*/}

                        <Route path={`/${settings.routes.signUp}`}>
                            <SignUpForm routes={settings.routes} settings={settings.components.signUp} />
                        </Route>

                        <Route path={`/${settings.routes.contactus}`}>
                            <ContactUs />
                        </Route>

                        <BottomBar routes={settings.routes} />
                    </Route>
                </Switch>
            </div>
        </div>
    ) : (
        <div className="display-flex-center" style={{ minHeight: "100vh", background: "#12161d" }}>
            <Loader type="TailSpin" color="#dfdfdf" height={50} width={50} />
        </div>
    );
}

export default App;
