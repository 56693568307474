import * as classes from './TOS.module.css';
//import { useTranslation } from 'react-i18next';

const TOS = (props) => {
  //const { t } = useTranslation();

  return (
    <div
      className={`${classes.tosContainer} font-300`}
      style={props?.styles?.tosContaine}
    >
      <div className={`${classes.tosMainTitle} font-600`}>
        {'Όροι & Κανονισμοί'}
      </div>
      <br />
      H Digital Tree Media Ltd (εφεξής η εταιρεία) στην οποία υπάγεται η
      διαδικτυακή πύλη DigitalTv.com.cy, προσφέρει τις υπηρεσίες της υπό τους
      κάτωθι όρους χρήσης τους οποίους ο επισκέπτης/χρήστης των σελίδων της
      διαδικτυακής πύλης καλείται να διαβάσει προσεκτικά και να προβεί σε
      επίσκεψη/χρήση των σελίδων/υπηρεσιών της DigitalTv.com.cy μόνο εφόσον τους
      αποδέχεται πλήρως.
      <br />
      H εταιρεία παρέχει στους επισκέπτες/χρήστες της διαδικτυακής πύλης
      DigitalTv.com.cy μια πλούσια συλλογή υπηρεσιών, σελίδων, επιλογών ή/και
      πηγών συμπεριλαμβανομένων διαφόρων εργαλείων επικοινωνίας, υπηρεσιών
      ηλεκτρονικού εμπορίου, ηλεκτρονικούς χώρους μαζικής πρόσβασης και
      επικοινωνίας των χρηστών, υπηρεσίες/σελίδες προσωπικού χαρακτήρα και
      περιεχομένου. Στις σελίδες της DigitalTv.com.cy συμπεριλαμβάνονται
      διαφημίσεις κάθε μορφής. Οι ήδη υπάρχουσες καθώς και οποιεσδήποτε νέες
      υπηρεσίες συμπεριληφθούν στην DigitalTv.com.cy υπόκεινται στους παρόντες
      όρους χρήσης, εκτός αν ρητά αναφέρεται το αντίθετο. Ο επισκέπτης / χρήστης
      των υπηρεσιών της DigitalTv.com.cy κατανοεί και αποδέχεται ότι το σύνολο
      των σελίδων/υπηρεσιών παρέχεται «όπως είναι» και η DigitalTv.com.cy
      αποποιείται οποιασδήποτε ευθύνης σχετικής με την περιορισμένη χρονική
      διάρκεια, τη διαγραφή, την κακή απόδοση ή την αδυναμία ηλεκτρονικής
      αποθήκευσης οποιωνδήποτε δεδομένων (data) των χρηστών ή/και οποιουδήποτε
      περιεχομένου προσωπικών σελίδων/υπηρεσιών. Ο επισκέπτης/χρήστης έχει την
      ευθύνη πρόσβασης στις υπηρεσίες της DigitalTv.com.cy και η σχετική
      πρόσβαση μπορεί να απαιτεί την καταβολή τελών σε τρίτους φορείς (π.χ.
      παροχείς υπηρεσιών internet, χρέωση χρόνου παραμονής στο internet). Ο
      επισκέπτης/χρήστης έχει την αποκλειστική ευθύνη πληρωμής των σχετικών
      τελών. Επίσης, ο επισκέπτης/χρήστης είναι αποκλειστικά υπεύθυνος για τον
      προσωπικό εξοπλισμό του με τα απαραίτητα τεχνολογικά μέσα που του
      επιτρέπουν την πρόσβαση στις υπηρεσίες της DigitalTv.com.cy.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΕΓΓΡΑΦΗ</div>
      <br />
      Εφόσον ο επισκέπτης/χρήστης επιθυμεί να εγγραφεί στην/στις υπηρεσία/ες της
      DigitalTv.com.cy συμφωνεί να: α) παρέχει αληθείς, ακριβείς, έγκυρες και
      πλήρεις πληροφορίες σχετικά με τα στοιχεία που του ζητούνται από την
      DigitalTv.com.cy στις σχετικές αιτήσεις για πρόσβαση στα
      περιεχόμενα/υπηρεσίες της και β) διατηρεί και επιμελώς ενημερώνει τα
      στοιχεία εγγραφής του ώστε να διατηρούνται αληθή, ακριβή, έγκυρα,
      ενημερωμένα και πλήρη.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΔΕΟΝΤΟΛΟΓΙΑ ΜΕΛΩΝ</div>
      <br />
      Είναι αμοιβαίως αποδεκτό και κατανοητό ότι, παρόλο που η DigitalTv.com.cy
      παρέχει στους χρήστες/μέλη του την απαραίτητη τεχνολογική υποδομή και τα
      μέσα για ανάρτηση/δημοσίευση περιεχομένου, όλες οι πληροφορίες, τα
      δεδομένα, τα κείμενα, τα γραφικά, οι φωτογραφίες, οι εικόνες, τα μουσικά
      αρχεία, τα βίντεο, τα μηνύματα και όλο το περιεχόμενο, είτε αναρτάται
      δημόσια είτε μεταφέρεται ιδιωτικά, παραμένει στην αποκλειστική ευθύνη του
      φυσικού ή νομικού προσώπου από το οποίο το περιεχόμενο πηγάζει. Αυτό
      σημαίνει ότι ο χρήστης/μέλος είναι αποκλειστικά υπεύθυνος για όλο και
      οποιοδήποτε περιεχόμενο αναρτά, δημοσιεύει, αποστέλλει, μεταφέρει ή άλλως
      καθιστά διαθέσιμο μέσω των υπηρεσιών της DigitalTv.com.cy. Η
      DigitalTv.com.cy δεν είναι δυνατό, λόγω του όγκου της, να ελέγχει το
      σύνολο του περιεχομένου που αναρτάται από τους χρήστες/μέλη του στις
      υπηρεσίες του δικτυακού τόπου, οπότε δεν εγγυάται την ακρίβεια, την
      ακεραιότητα, τη νομιμότητα, ή την ποιότητα τέτοιου περιεχομένου. Ο
      χρήστης/μέλος κατανοεί και αποδέχεται ότι χρησιμοποιώντας τις υπηρεσίες
      της DigitalTv.com.cy μπορεί να εκτεθεί σε περιεχόμενο προσβλητικό, ανήθικο
      ή παράνομο. Σε καμία περίπτωση δεν μπορεί να θεωρηθεί υπεύθυνη την
      DigitalTv.com.cy, για οποιοδήποτε λάθος ή παραλείψεις σε οποιοδήποτε
      περιεχόμενο ή για οποιαδήποτε βλάβη ή ζημία που τυχόν προκύψουν από τη
      χρήση οποιουδήποτε περιεχομένου που αναρτάται, αποστέλλεται, μεταφέρεται ή
      άλλως καθίσταται διαθέσιμο από τους χρήστες/μέλη στις υπηρεσίες της
      DigitalTv.com.cy. Σε περίπτωση πoυ η DigitalTv.com.cy λάβει ειδοποίηση ότι
      οποιοδήποτε περιεχόμενο προκαλεί ηθική βλάβη ή άλλη ζημία σε τρίτο πρόσωπο
      διατηρεί το δικαίωμα να προβεί στην άμεση διαγραφή του περιεχομένου αυτού
      και ταυτόχρονα να διακόψει τη λειτουργία του λογαριασμού του χρήστη/μέλους
      ο οποίος παραβιάζει τους όρους του παρόντος.
      <br />
      <br />
      Οι χρήστες/μέλη συμφωνούν να μην κάνουν χρήση των υπηρεσιών της
      DigitalTv.com.cy για:
      <br />
      <br />
      <div className={classes.padded}>
        <ol>
          <li>
            Ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή χρήση άλλης μεθόδου για
            την εγκατάσταση περιεχομένου που είναι παράνομο, επιβλαβές,
            απειλητικό, προσβλητικό, επιζήμιο, δυσφημιστικό, χυδαίο, βίαιο,
            υβριστικό, ρατσιστικό ή άλλως αποδοκιμαστέο, παραβιάζει την
            προσωπικότητα και τα προσωπικά δεδομένα άλλων, προκαλεί συναισθήματα
            μίσους, ή/και οποιοδήποτε άλλο συνιστώμενο ποινικό αδίκημα.
          </li>

          <li>Πρόκληση βλάβης σε ανήλικους με οποιοδήποτε τρόπο.</li>

          <li>
            Μίμηση οποιουδήποτε νομικού ή φυσικού προσώπου ή ψευδή δήλωση για
            την ταυτότητα του χρήστη/μέλους ή παραπλανητική δήλωση αναφορικά με
            τη σχέση ή/και συνεργασία του χρήστη/μέλους με κάποιο άλλο νομικό ή
            φυσικό πρόσωπο.
          </li>

          <li>
            Παραχάραξη ή άλλη αλλοίωση των αναγνωριστικών των χρηστών/μελών με
            σκοπό την παραπλάνηση ως προς την προέλευση του περιεχομένου που
            μεταδίδεται μέσω των υπηρεσιών της DigitalTv.com.cy.
          </li>

          <li>
            Ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή χρήση άλλης μεθόδου για
            την εγκατάσταση περιεχομένου από άτομο που δεν έχει δικαίωμα να
            διαθέσει το συγκεκριμένο περιεχόμενο βάσει νόμου ή βάσει
            εμπιστευτικής σχέσης.
          </li>

          <li>
            Ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή χρήση άλλης μεθόδου για
            την εγκατάσταση λογισμικού ή περιεχομένου κάθε μορφής (κειμένου,
            εικόνας, ήχου, video, animation) που παραβιάζει οποιαδήποτε
            δικαιώματα πνευματικής ιδιοκτησίας οποιουδήποτε φορέα
            (συμπεριλαμβανομένων των εμπορικών μυστικών).
          </li>

          <li>
            Ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή χρήση άλλης μεθόδου για
            την εγκατάσταση αυτόκλητης ή μη εξουσιοδοτημένης διαφήμισης ή άλλου
            περιεχομένου προώθησης προϊόντων, την αποστολή ανεπιθύμητων και μη
            προσκαλουμένων από τον λήπτη ηλεκτρονικών μηνυμάτων (e-mails) και
            οποιαδήποτε άλλη μορφή ανεπιθύμητης προώθησης περιεχομένου.
          </li>

          <li>
            Ανάρτηση, δημοσίευση, αποστολή, μεταφορά ή χρήση άλλης μεθόδου για
            την εγκατάσταση ή/και προώθηση ή/και διάθεση περιεχομένου που
            περιέχει ψηφιακούς ιούς ή οποιοδήποτε άλλο ηλεκτρονικό κώδικα,
            αρχεία ή προγράμματα σχεδιασμένα να παρεμβληθούν, καταστρέψουν ή
            περιορίσουν τη λειτουργία οποιουδήποτε λογισμικού ή εξοπλισμού
            ηλεκτρονικών υπολογιστών ή τηλεπικοινωνιακού εξοπλισμού.
          </li>

          <li>
            Παρεμβολή στις υπηρεσίες ή διάσπαση των υπηρεσιών ή των servers ή
            των δικτύων που είναι συνδεδεμένα με τις υπηρεσίες της
            DigitalTv.com.cy, ή παρακοή των προϋποθέσεων, διαδικασιών και
            κανόνων χρήσης των δικτύων αυτών.
          </li>

          <li>
            Παραβίαση, με ή χωρίς πρόθεση, οποιασδήποτε τοπικής, εθνικής,
            ευρωπαϊκής, διεθνούς νομοθεσίας ή/και οποιουδήποτε κανόνα που έχει
            νομοθετική ισχύ και αφορά ή/και καλύπτει οποιαδήποτε υπηρεσία της
            DigitalTv.com.cy.
          </li>

          <li>
            Παρενόχληση με οποιονδήποτε τρόπο της ιδιωτικής ζωής και των
            ατομικών και κοινωνικών δικαιωμάτων άλλων χρηστών/μελών (όπως η
            συλλογή ή/και αποθήκευση προσωπικών δεδομένων άλλων χρηστών/μελών).
          </li>
        </ol>
      </div>
      <br />
      <br />
      Ο χρήστης/μέλος της DigitalTv.com.cy κατανοεί και αποδέχεται ότι η
      DigitalTv.com.cy δεν κάνει προκαταρκτικό έλεγχο του περιεχομένου, αλλά ότι
      η DigitalTv.com.cy και οι αρμόδιοι συνεργάτες της διατηρούν το δικαίωμα
      (και όχι την υποχρέωση) της αποκλειστικής επιλογής άρνησης
      ανάρτησης/δημοσίευσης ή μετακίνησης ή διαγραφής οποιουδήποτε περιεχομένου
      διατίθεται μέσω των υπηρεσιών της DigitalTv.com.cy. Επίσης, η
      DigitalTv.com.cy και οι αρμόδιοι συνεργάτες της διατηρούν το δικαίωμα να
      διαγράφουν οποιοδήποτε περιεχόμενο παραβιάζει τους παρόντες όρους χρήσης.
      Ο χρήστης/μέλος κατανοεί και αποδέχεται ότι πρέπει να αξιολογεί και να
      είναι υπεύθυνος για κάθε κίνδυνο που μπορεί να ενέχει η χρήση οποιουδήποτε
      περιεχομένου, συμπεριλαμβανομένης οποιασδήποτε απόφασης του να βασιστεί
      στην ορθότητα, την πληρότητα ή/και τη χρησιμότητα οποιουδήποτε
      περιεχομένου. Υπό αυτή την έννοια, ο χρήστης/μέλος κατανοεί και αποδέχεται
      ότι δεν μπορεί να βασίζεται στο περιεχόμενο που δημιουργεί η
      DigitalTv.com.cy ή κατατίθεται για ανάρτηση σε αυτή, συμπεριλαμβανομένων
      των πληροφοριών που διατίθενται στην DigitalTv.com.cy, των chat rooms, των
      σελίδων χρηστών και όλων των άλλων υπηρεσιών της DigitalTv.com.cy. Ο
      χρήστης/μέλος κατανοεί και αποδέχεται τη διεθνή φύση του Διαδικτύου
      (internet) και δεσμεύεται να σέβεται και να τηρεί πιστά τους τοπικούς
      κανόνες δεοντολογίας κάθε κράτους. Συγκεκριμένα, ο χρήστης/μέλος
      δεσμεύεται να ακολουθεί πιστά τη νομοθεσία που αφορά την μετάδοση
      δεδομένων από την Κύπρο και την Ευρώπη προς τρίτα κράτη.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΠΑΡΟΧΗ ΑΔΕΙΑΣ ΓΙΑ ΔΙΑΦΗΜΙΣΤΙΚΟΥΣ ΛΟΓΟΥΣ
      </div>
      <br />
      Ο χρήστης/μέλος που κάνει χρήση των υπηρεσιών της DigitalTv.com.cy για να
      αναρτά ή/και δημοσιεύει πληροφορίες, δεδομένα, κείμενα, γραφικά,
      φωτογραφίες, εικόνες, μουσικά αρχεία, βίντεο, μηνύματα, παρέχει την άδεια
      στην DigitalTv.com.cy, για το χρονικό διάστημα που το περιεχόμενο αυτό
      αποτελεί μέρος των υπηρεσιών της DigitalTv.com.cy, να χρησιμοποιεί το χώρο
      που έχει αναρτηθεί το περιεχόμενο για διαφημιστικούς λόγους. Επίσης, ο
      χρήστης/μέλος που αναρτά ή/και δημοσιεύει πληροφορίες, δεδομένα, κείμενα,
      γραφικά, φωτογραφίες, εικόνες, μουσικά αρχεία, βίντεο, μηνύματα συναινεί
      στην ανάρτηση ή/και δημοσίευση διαφημίσεων από την DigitalTv.com.cy στις
      σχετικές σελίδες/υπηρεσίες.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΒΛΑΒΗ ΑΠΟΖΗΜΙΩΣΗ</div>
      <br />
      Ο χρήστης/μέλος της DigitalTv.com.cy κατανοεί και αποδέχεται ότι διατηρεί
      την αποκλειστική ευθύνη να αποζημιώσει την DigitalTv.com.cy και τους
      συνεργάτες της για οποιαδήποτε νομική διαμάχη προκύψει μεταξύ αυτού και
      τρίτων φορέων λόγω του περιεχομένου που αυτός διαθέσει για ανάρτηση,
      δημοσίευση, ή άλλη μεταφορά μέσω των υπηρεσιών της DigitalTv.com.cy.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΕΝΑΡΞΗ-ΔΙΑΚΟΠΗ ΛΕΙΤΟΥΡΓΙΑΣ ΥΠΗΡΕΣΙΩΝ
      </div>
      <br />
      Η DigitalTv.com.cy διατηρεί το δικαίωμα να τροποποιεί ή/και να διακόπτει
      προσωρινά ή μόνιμα μέρος ή το σύνολο των υπηρεσιών του με ή χωρίς
      προειδοποίηση προς τους χρήστες/μέλη.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΔΙΑΡΚΕΙΑ</div>
      <br />
      Ο χρήστης/μέλος κατανοεί και αποδέχεται ότι η DigitalTv.com.cy διατηρεί το
      αποκλειστικό δικαίωμα να διακόψει τη χρήση του/των κωδικών πρόσβασης στις
      υπηρεσίες της ή/και να διακόψει τη διάθεση του περιεχομένου της στους
      χρήστες/μέλη που πιστεύει ότι έχουν παραβιάσει το γράμμα και το πνεύμα των
      παρόντων όρων χρήσης.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΔΙΑΦΗΜΙΣΗ</div>
      <br />
      Η DigitalTv.com.cy δεν έχει καμία ευθύνη για την επικοινωνία του
      χρήστη/μέλους με τους τρίτους παροχείς υπηρεσιών που διαφημίζονται στην
      DigitalTv.com.cy και για την οποιαδήποτε τυχόν εμπορική συναλλαγή που
      μπορεί να προκύψει από τη μεταξύ τους σχέση.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΔΙΚΑΙΩΜΑΤΑ ΠΝΕΥΜΑΤΙΚΗΣ ΚΑΙ ΒΙΟΜΗΧΑΝΙΚΗΣ ΙΔΙΟΚΤΗΣΙΑΣ - ΣΗΜΑΤΑ
      </div>
      <br />
      Εκτός των ρητά αναφερόμενων εξαιρέσεων (πνευματικά δικαιώματα τρίτων,
      συνεργατών και φορέων), όλο το περιεχόμενο της DigitalTv.com.cy,
      συμπεριλαμβανομένων εικόνων, γραφικών, φωτογραφιών, σχεδίων, κειμένων, των
      παρεχομένων υπηρεσιών και γενικά όλων των αρχείων αυτού του δικτυακού
      τόπου, αποτελούν πνευματική ιδιοκτησία, κατατεθειμένα σήματα και σήματα
      υπηρεσιών της DigitalTv.com.cy και προστατεύονται από τις σχετικές
      διατάξεις του Κυπριακού δικαίου, του ευρωπαϊκού δικαίου και των διεθνών
      συμβάσεων και συνθηκών. Συνεπώς, κανένα εξ αυτών δε δύναται να αποτελέσει
      εν όλω ή εν μέρει αντικείμενο πώλησης, αντιγραφής, τροποποίησης,
      αναπαραγωγής, αναδημοσίευσης ή να "φορτωθεί", να μεταδοθεί ή να διανεμηθεί
      με οποιονδήποτε τρόπο. Εξαιρείται η περίπτωση της μεμονωμένης αποθήκευσης
      ενός και μόνου αντιγράφου τμήματος του περιεχομένου σε έναν απλό προσωπικό
      ηλεκτρονικό υπολογιστή, για προσωπική και όχι δημόσια ή εμπορική χρήση και
      χωρίς απαλοιφή της ένδειξης προέλευσής τους από την DigitalTv.com.cy,
      χωρίς να θίγονται με κανένα τρόπο τα σχετικά δικαιώματα πνευματικής και
      βιομηχανικής ιδιοκτησίας. Τα λοιπά προϊόντα ή υπηρεσίες που αναφέρονται
      στις ηλεκτρονικές σελίδες του παρόντος κόμβου και φέρουν τα σήματα των
      αντίστοιχων οργανισμών, εταιρειών, συνεργατών φορέων, ενώσεων ή εκδόσεων,
      αποτελούν δική τους πνευματική και βιομηχανική ιδιοκτησία και συνεπώς οι
      φορείς αυτοί φέρουν τη σχετική ευθύνη. Ο χρήστης/μέλος κατανοεί και
      αποδέχεται ότι δεν του παρέχεται το δικαίωμα να αναπαράγει, αντιγράφει,
      πωλεί, μεταπωλεί ή/και εκμεταλλεύεται εμπορικά με οποιονδήποτε τρόπο
      σύνολο ή μέρος του περιεχομένου της DigitalTv.com.cy.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΠΕΡΙΟΡΙΣΜΟΣ ΕΥΘΥΝΗΣ</div>
      <br />
      Δεδομένης της φύσης και του όγκου του Διαδικτύου, υπό οποιεσδήποτε
      συνθήκες, συμπεριλαμβανομένης και της περίπτωσης αμέλειας, η
      DigitalTv.com.cy δεν ευθύνεται για οποιασδήποτε μορφής ζημία υποστεί ο
      επισκέπτης/χρήστης των σελίδων, υπηρεσιών, επιλογών και περιεχομένων της
      DigitalTv.com.cy στις οποίες προβαίνει με δική του πρωτοβουλία. Τα
      περιεχόμενα της DigitalTv.com.cy παρέχονται «όπως ακριβώς είναι» χωρίς
      καμία εγγύηση εκπεφρασμένη ή και συνεπαγόμενη με οποιοδήποτε τρόπο. Στο
      μέγιστο βαθμό και σύμφωνα με το νόμο, η DigitalTv.com.cy αρνείται όλες τις
      εγγυήσεις εκπεφρασμένες ή και συνεπαγόμενες, συμπεριλαμβανομένων, όχι όμως
      περιοριζόμενων σε αυτή, αυτών, οι οποίες συνεπάγονται την εμπορευσιμότητα
      και την καταλληλότητα για ένα συγκεκριμένο σκοπό. Η DigitalTv.com.cy δεν
      εγγυάται ότι οι σελίδες, οι υπηρεσίες, οι επιλογές και τα περιεχόμενα θα
      παρέχονται χωρίς διακοπή, χωρίς σφάλματα και ότι τα λάθη θα διορθώνονται.
      Επίσης η DigitalTv.com.cy δεν εγγυάται ότι το ίδιο ή οποιοδήποτε άλλο
      συγγενικό site ή οι εξυπηρετητές (servers) μέσω των οποίων αυτά τίθενται
      στη διάθεσή των χρηστών/μελών, δεν περιέχουν "ιούς" ή άλλα επιζήμια
      συστατικά. Η DigitalTv.com.cy δεν εγγυάται σε καμία περίπτωση την
      ορθότητα, την πληρότητα ή και διαθεσιμότητα των περιεχομένων, σελίδων,
      υπηρεσιών, επιλογών ή τα αποτελέσματά τους. Το κόστος των ενδεχόμενων
      διορθώσεων ή εξυπηρετήσεων, το αναλαμβάνει ο επισκέπτης/χρήστης και σε
      καμία περίπτωση η DigitalTv.com.cy.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΑΠΟΚΛΕΙΣΜΟΣ ΕΥΘΥΝΗΣ ΓΙΑ ΠΛΗΡΟΦΟΡΙΕΣ/ΣΥΜΒΟΥΛΕΣ
      </div>
      <br />
      Το περιεχόμενο και οι πληροφορίες που περιλαμβάνονται στην
      DigitalTv.com.cy αποτελούν μία προσφορά προς τον επισκέπτη/χρήστη της
      DigitalTv.com.cy και γενικά προς την κοινότητα των χρηστών του Internet
      και δε δύνανται σε καμιά περίπτωση να εκληφθούν ως έγκυρες πληροφορίες
      ή/και συμβουλές ούτε υποκρύπτουν σε καμία περίπτωση οποιαδήποτε προτροπή
      για την επιχείρηση ή μη συγκεκριμένων πράξεων. Η DigitalTv.com.cy
      αναλαμβάνει τη συλλογή, επεξεργασία και διανομή του περιεχομένου του,
      χωρίς όμως σε καμιά περίπτωση να εγγυάται την αρτιότητα, την πληρότητα,
      την επάρκεια και γενικά την καταλληλότητα αυτού και την απουσία
      ενδεχομένων λαθών πολύ δε περισσότερο λόγω του ιδιαιτέρως μεγάλου όγκου
      του, καθώς και της συμμετοχής και τρίτων φορέων (φυσικών ή νομικών
      προσώπων) κατά την πρωτογενή παραγωγή και συλλογή του. Συνεπώς, οι
      επισκέπτες / χρήστες της DigitalTv.com.cy, χρησιμοποιώντας τις υπηρεσίες
      της με δική τους πρωτοβουλία, αναλαμβάνουν και τη σχετική ευθύνη
      διασταύρωσης των παρεχομένων πληροφοριών.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        'ΔΕΣΜΟΙ' (LINKS) ΠΡΟΣ ΑΛΛΟΥΣ ΔΙΚΤΥΑΚΟΥΣ ΤΟΠΟΥΣ (SITES)
      </div>
      <br />
      Η DigitalTv.com.cy δεν ελέγχει τη διαθεσιμότητα, το περιεχόμενο, την
      πολιτική προστασίας των προσωπικών δεδομένων, την ποιότητα και την
      πληρότητα των υπηρεσιών άλλων web sites και σελίδων στα οποία παραπέμπει
      μέσω "δεσμών", hyperlinks ή διαφημιστικών banners. Συνεπώς, για
      οποιοδήποτε πρόβλημα παρουσιαστεί κατά την επίσκεψη/χρήση τους, ο χρήστης
      οφείλει να απευθυνθεί απευθείας στα αντίστοιχα web sites και σελίδες, τα
      οποία και φέρουν ακέραια τη σχετική ευθύνη για την παροχή των υπηρεσιών
      τους. Η DigitalTv.com.cy σε καμία περίπτωση δεν πρέπει να θεωρηθεί ότι
      ενστερνίζεται ή αποδέχεται το περιεχόμενο ή τις υπηρεσίες των web sites
      και των σελίδων στα οποία παραπέμπει ή ότι συνδέεται με αυτά κατά
      οποιονδήποτε άλλο τρόπο.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΗΛΕΚΤΡΟΝΙΚΟ ΕΜΠΟΡΙΟ (E-COMMERCE)
      </div>
      <br />
      Η DigitalTv.com.cy παρέχει στους επισκέπτες/χρήστες του τη δυνατότητα
      αγοράς υπηρεσιών ή/και προϊόντων μέσω εφαρμογών ηλεκτρονικού εμπορίου
      σύμφωνα με τους ειδικότερους όρους που αυτό τάσσει και μεριμνώντας για την
      προστασία των προσωπικών στοιχείων που υποβάλλουν για τη χρήση των
      υπηρεσιών αυτών. Η DigitalTv.com.cy δεν ευθύνεται για την ποιότητα των
      αγαθών που αποκτώνται μέσω εφαρμογών ηλεκτρονικού εμπορίου και η συναλλαγή
      δεσμεύει αποκλειστικά τον επισκέπτη/χρήστη και την εταιρεία παροχής αγαθών
      ή υπηρεσιών. Συνεπώς σε καμία περίπτωση δεν μπορεί να εμπλακεί η
      DigitalTv.com.cy σε σχετική δικαστική διαφορά που προκύπτει από τη
      συναλλαγή αυτή. Στις περιπτώσεις που οι εφαρμογές ηλεκτρονικού εμπορίου
      πραγματοποιούνται μεταξύ των χρηστών/μελών και της DigitalTv.com.cy, η
      DigitalTv.com.cy είναι υποχρεωμένη να συμμορφώνεται προς τον Ν.14(Ι)/2000
      για τις συμβάσεις εξ’ αποστάσεως και τις διατάξεις του Νόμου 93(Ι)/96 περί
      καταχρηστικών ρητρών σε καταναλωτικές συμβάσεις. Έτσι, η DigitalTv.com.cy
      υποχρεούται να ενημερώνει τους υποψήφιους πελάτες της σχετικά με α) τα
      ουσιώδη χαρακτηριστικά του αγαθού ή/και των υπηρεσιών που προσφέρει, β)
      την τιμή, γ) την ποσότητα και τις δαπάνες μεταφοράς, δ) το φόρο
      προστιθέμενης αξίας εφόσον δεν περιλαμβάνεται στην τιμή, ε) τον τρόπο
      πληρωμής, στ) τον τρόπο παράδοσης και εκτέλεσης, ζ) τη διάρκεια ισχύος της
      προσφοράς ή της τιμής και η) το δικαίωμα υπαναχώρησης. Η πρόσβαση στις
      σελίδες/υπηρεσίες της DigitalTv.com.cy δεν συνεπάγεται κανένα επιπλέον
      κόστος πέραν του ισχύοντος τιμολογιακού καθεστώτος για την γενική πρόσβαση
      στο Διαδίκτυο όπως έχει διαμορφωθεί από τους αρμόδιους παροχείς (ISP's)
      και καταβάλλεται σε αυτούς και μόνο.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΥΠΗΡΕΣΙΕΣ ΚΑΙ ΣΕΛΙΔΕΣ ΠΡΟΣΩΠΙΚΟΥ ΧΑΡΑΚΤΗΡΑ ΚΑΙ ΠΕΡΙΕΧΟΜΕΝΟΥ
      </div>
      <br />
      Ο χρήστης/μέλος κατανοεί και αποδέχεται ότι η DigitalTv.com.cy μπορεί να
      υιοθετήσει νέους όρους χρήσης των υπηρεσιών της συμπεριλαμβανομένων των
      χρονικών ορίων που θα διατηρούνται στις υπηρεσίες της DigitalTv.com.cy τα
      ηλεκτρονικά μηνύματα (e-mails), τα μηνύματα (forums), οι σελίδες χρηστών,
      ή/και οποιοδήποτε άλλο περιεχόμενο που αναρτά ή/και δημοσιεύει ο
      χρήστης/μέλος. Επίσης, η DigitalTv.com.cy διατηρεί το δικαίωμα να
      τροποποιήσει τα επιτρεπόμενα όρια που αφορούν το μέγεθος και τον αριθμό
      των ηλεκτρονικών μηνυμάτων (e-mails) που μπορεί να αποστέλλει ο
      χρήστης/μέλος από τον λογαριασμό του, των μηνυμάτων ή/και οποιοδήποτε άλλο
      περιεχόμενο που αναρτά και δημοσιεύει ο χρήστης/μέλος στις υπηρεσίες της
      DigitalTv.com.cy. Η DigitalTv.com.cy διατηρεί το δικαίωμα να τροποποιήσει,
      ανά πάσα στιγμή και χωρίς προειδοποίηση, τα όρια αποθηκευτικού χώρου
      περιεχομένου που παρέχει μέσω των υπηρεσιών της στους χρήστες/μέλη. Οι
      χρήστες/μέλη κατανοούν και αποδέχονται ότι η DigitalTv.com.cy διατηρεί το
      δικαίωμα να απενεργοποιεί λογαριασμούς ηλεκτρονικής αλληλογραφίας που
      παραμένουν ανενεργοί από τους χρήστες/μέλη για μακρύ χρονικό διάστημα.
      (μεγαλύτερο των 6 μηνών)
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΟΜΑΔΕΣ ΣΥΖΗΤΗΣΕΩΝ (FORUMS)
      </div>
      <br />
      Η DigitalTv.com.cy παρέχει στους επισκέπτες/χρήστες τη δυνατότητα να
      αναπτύξουν τα δικά τους message boards, παρέχοντας τους παράλληλα τη
      δυνατότητα να λάβουν μέρος στη συζήτηση message boards που έχουν
      δημιουργήσει άλλοι χρήστες με την αποστολή γραπτών μηνυμάτων. Οι
      επισκέπτες/χρήστες της DigitalTv.com.cy οφείλουν να τηρούν τους κανόνες
      της καλής συμπεριφοράς και της ευπρέπειας και να μην προβαίνουν σε
      παράνομες ή ανήθικες διατυπώσεις. Η DigitalTv.com.cy διατηρεί το δικαίωμα
      να απαγορεύσει ή να διακόψει την προβολή του γραπτού μηνύματος σε
      περίπτωση παράβασης των ανωτέρω όρων. Η DigitalTv.com.cy σε καμία
      περίπτωση δεν μπορεί να θεωρηθεί ότι αποδέχεται ή ενστερνίζεται κατά
      οποιονδήποτε τρόπο τις εκφραζόμενες σε αυτούς τους χώρους προσωπικές ιδέες
      ή αντιλήψεις. Οι επισκέπτες / χρήστες των σχετικών υπηρεσιών διατηρούν το
      δικαίωμα πνευματικής ιδιοκτησίας στις απόψεις που εκφράζουν με τα μηνύματά
      τους. Σε περίπτωση που η DigitalTv.com.cy λάβει ειδοποίηση ότι το
      περιεχόμενο κάποιου/κάποιων μηνυμάτων που αναρτούν/δημοσιεύουν οι χρήστες
      μέλη θίγει τρίτα πρόσωπα διατηρεί το δικαίωμα να προβεί στην άμεση
      διαγραφή του μηνύματος ή ακόμα και του λογαριασμού του χρήστη που το
      δημιούργησε.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΨΗΦΟΦΟΡΙΕΣ</div>
      <br />
      Η DigitalTv.com.cy παρέχει στους επισκέπτες / χρήστες των υπηρεσιών του τη
      δυνατότητα ψηφοφορίας πάνω σε επίκαιρα ζητήματα τα οποία διατυπώνει με τη
      μορφή ερωτημάτων. Οι απαντήσεις των ψηφοφόρων καταγράφονται, αναλύονται
      και σχολιάζονται από την DigitalTv.com.cy για την εξαγωγή συμπερασμάτων
      σχετικά με τις θέσεις της κοινής γνώμης σε σχέση με το συγκεκριμένο θέμα
      που τίθεται για ψηφοφορία. Η DigitalTv.com.cy διατηρεί το αποκλειστικό
      δικαίωμα συλλογής και εκμετάλλευσης των στοιχείων αυτών και τα πορίσματα
      στα οποία οδηγείται αποτελούν πνευματική της ιδιοκτησία. Η
      DigitalTv.com.cy καθορίζει τους ειδικότερους όρους για τη συμμετοχή στην
      ψηφοφορία και διατηρεί το δικαίωμα διακοπής της ψηφοφορίας.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΠΡΟΣΤΑΣΙΑ ΑΝΗΛΙΚΩΝ</div>
      <br />
      Επισκέπτες/χρήστες της DigitalTv.com.cy που είναι ανήλικοι δεν επιτρέπεται
      να έχουν πρόσβαση στις υπηρεσίες της DigitalTv.com.cy που μπορεί να
      θεωρούνται ακατάλληλες για ανηλίκους και οι οποίες δεν είναι δυνατό να
      ελεγχθούν από την DigitalTv.com.cy. Σε κάθε ενότητα ή υπηρεσία που μπορεί
      να περιέχει υλικό ακατάλληλο/προσβλητικό/ανήθικο υπάρχει προειδοποίηση
      προς τους επισκέπτες/χρήστες. Εάν παρόλα αυτά ανήλικοι χρήστες αυτοβούλως
      επισκεφτούν σελίδες με υλικό ακατάλληλο/προσβλητικό/ανήθικο και το οποίο
      δεν είναι δυνατό να ελέγχεται συνεχώς, η DigitalTv.com.cy δεν φέρει καμία
      ευθύνη.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>COOKIES</div>
      <br />
      Η DigitalTv.com.cy σέβεται απόλυτα τα προσωπικά δικαιώματα όλων των
      επισκεπτών της γι’ αυτό σας παραθέτει την ακόλουθη πολιτική προστασίας των
      προσωπικών σας δεδομένων κατά την διάρκεια παραμονής σας στην ιστοσελίδα
      μας. Πρωταρχικός στόχος της συλλογής προσωπικών πληροφοριών από εσάς είναι
      η παροχή μιας ομαλής, αποτελεσματικής και εξατομικευμένης εμπειρίας κατά
      τη χρήση της DigitalTv.com.cy. Η ιστοσελίδα μας χρησιμοποιεί web analytics
      εργαλεία, για καλύτερη κατανόηση της απόδοσης του ιστοχώρου, για ανάλυση
      της χρήσης της ιστοσελίδας, καθορισμό του τρόπου με τον οποίο οι χρήστες
      επισκέπτονται την ιστοσελίδα μας, σε τι περιεχόμενο πλοηγείται και τι
      πρόγραμμα περιήγησης / λειτουργικό σύστημα είναι σε χρήση. Αυτά τα
      δεδομένα δεν συνδέονται με προσωπικά στοιχεία, αλλά είναι απαραίτητα για
      να διαπιστώσουμε τυχόν προβλήματα της ιστοσελίδας μας, καθώς και να
      ανακαλύψουμε ποιες υπηρεσίες και ποια χαρακτηριστικά είναι πιο πολύτιμα
      για τους χρήστες, έτσι ώστε να μπορούμε να συνεχίσουμε να βελτιώνουμε την
      εμπειρία των χρηστών στην DigitalTv.com.cy. Χρησιμοποιούμε έναν αριθμό
      διαφορετικών “cookies” στην ιστοσελίδα DigitalTv.com.cy. Τα cookies είναι
      μικρά αρχεία κειμένου που τοποθετούνται στον υπολογιστή σας από τις
      ιστοσελίδες που επισκέπτεστε. Χρησιμοποιούνται ευρέως για να κάνουν τις
      ιστοσελίδες να δουλέψουν ή να λειτουργήσουν πιο αποτελεσματικά, καθώς και
      για να παρέχουν πληροφορίες στους ιδιοκτήτες της ιστοσελίδας. Για
      περισσότερες πληροφορίες σχετικά με το τι είναι τα cookies και πώς
      λειτουργούν, ή για να μάθετε πώς να τα ελέγξετε ή να τα διαγράψετε,
      παρακαλώ πατήστε εδώ . Παρακάτω θα βρείτε πληροφορίες σχετικά με τα
      cookies που χρησιμοποιούμε στην DigitalTv.com.cy και τους λόγους που
      χρησιμοποιούνται. Η χρήση αυτού του δικτυακού τόπου και η αποδοχή της
      κοινοποίησης των “cookies” μας, υποδηλώνει ότι είστε ευχαριστημένοι με τη
      χρήση τους και παρέχετε τη συγκατάθεσή σας. Εάν δεν παρέχετε τη
      συγκατάθεση σας, τότε θα πρέπει να μην χρησιμοποιείτε την ιστοσελίδα
      DigitalTv.com.cy ή να διαγράψετε τα cookies μετά την έξοδό σας αυτήν.
      Εναλλακτικά μπορείτε να χρησιμοποιήσετε το πρόγραμμα περιήγησης σας σε
      ανώνυμη ή ιδιωτική λειτουργία. "Session Cookies": Μπορεί να
      χρησιμοποιήσουμε ένα “session cookie” για να θυμόμαστε το log-in σας για
      εσάς. Αν αυτό το cookie είναι απενεργοποιημένο τότε στη συνέχεια κάποιες
      βασικές λειτουργίες δεν θα μπορούν να χρησιμοποιηθούν. Για περισσότερες
      πληροφορίες σχετικά με τα “session cookies” και το πώς χρησιμοποιούνται
      πατήστε εδώ . “Persistent (Μόνιμα;) Cookies”: Για την ανάλυση και απόδοση
      του ιστοχώρου χρησιμοποιούμε μερικά μόνιμα cookies για τη συλλογή ανώνυμων
      δεδομένων χρήσης για βελτιστοποίηση της εμπειρίας της ιστοσελίδας μας. Για
      περισσότερες πληροφορίες σχετικά με τα μόνιμα cookies και το πως
      χρησιμοποιούνται πατήστε εδώ. Google AdSense: Αυτό το cookie
      χρησιμοποιείται από την Google για να μας ενημερώνει για την απόδοση των
      διαφημίσεων που εμφανίζονται στην DigitalTv.com.cy. Τα δεδομένα του χρήστη
      είναι ανώνυμα. Για περισσότερες πληροφορίες σχετικά με τις πολιτικές της
      Google για τη διαφήμιση πατήστε εδώ . Αυτό το “cookie” είναι πολύ παρόμοιο
      με το προηγούμενο (και τα δύο ανήκουν / ελέγχονται από τη Google), αλλά
      είναι ειδικό για την απεικόνιση των διαφημίσεων στην ιστοσελίδα
      DigitalTv.com.cy. Τα δεδομένα των χρηστών είναι και πάλι ανώνυμα. Για
      περισσότερες πληροφορίες σχετικά με τις πολιτικές της Google για τη
      διαφήμιση θα διαβάσετε εδώ. Remarketing: Μπορούμε επίσης να
      χρησιμοποιήσουμε cookies για επαναληπτικό marketing (Remarketing). Μέσω
      αυτών των cookies μπορούμε να καταλήξουμε σε χρήστες που είχαν
      προηγουμένως επισκεφθεί την ιστοσελίδα μας και έχουν δείξει ενδιαφέρον για
      τα προϊόντα μας. Από καιρό σε καιρό, μπορούμε να χρησιμοποιήσουμε τρίτους
      προμηθευτές όπως η Google και ο DoubleClick, για να εμφανιστεί η διαφήμισή
      μας σε εσάς μέσω του διαδικτύου, με βάση την προηγούμενη χρήση σας στο
      δικτυακό μας τόπο. Μπορείτε να επιλέξετε ανά πάσα στιγμή τη χρήση των
      αυτών των cookies με βάση τα ενδιαφέροντα σας και το ιστορικό περιήγησης
      σας μέσω των ρυθμίσεων της Google Ads και της σελίδας opt-out του
      DoubleClick. Μπορείτε επίσης να διαχειριστείτε τα cookies που
      χρησιμοποιούνται για διαδικτυακές διαφημίσεις μέσω της σελίδας
      «aboutads.info», με έδρα τις ΗΠΑ, ή «Your Online Choice», με έδρα την ΕΕ.
      Τέλος, μπορείτε να διαχειριστείτε τα cookies μέσω του προγράμματος
      περιήγησης που χρησιμοποιείτε. Εξωτερικές υπηρεσίες web: Χρησιμοποιούμε
      μια σειρά από εξωτερικές web υπηρεσίες στην DigitalTv.com.cy, ως επί το
      πλείστον για την εμφάνιση περιεχομένου μέσα από τις ιστοσελίδες μας ,
      καθώς και για κουμπιά κοινωνικής δικτύωσης και plugins . Για παράδειγμα ,
      για να δείτε ένα βίντεο μπορεί να ενσωματώσουμε περιεχόμενο από το YouTube
      ή το Vimeo . Δεν μπορούμε να αποτρέψουμε αυτούς τους εξωτερικούς φορείς ,
      από τη συλλογή πληροφοριών σχετικά με τον τρόπο χρήσης του ενσωματωμένου
      περιεχομένου , και μπορεί να συλλέξουν ανώνυμα δεδομένα χρήσης , π.χ.
      αριθμό προβολής , και εάν είστε συνδεδεμένοι με αυτές τις εξωτερικές
      υπηρεσίες μπορεί να συγκεντρώσουν ατομικά δεδομένα χρήσης . Θα πρέπει να
      ελέγξετε τις αντίστοιχες πολιτικές του καθενός από αυτά τα sites για να
      δείτε πώς ακριβώς χρησιμοποιούν τις πληροφορίες σας και να μάθετε πώς
      μπορείτε να αποχωρήσετε , ή να διαγράψετε αυτές τις πληροφορίες.
      Παρακολούθηση του ηλεκτρονικού ταχυδρομείου: Χρησιμοποιούμε επίσης
      τεχνολογία παρακολούθησης των ενημερωτικών δελτίων που αποστέλλουμε μέσω
      του ηλεκτρονικού μας ταχυδρομείου για να γνωρίζουμε ποια emails έχουν
      ανοιχτεί από τους παραλήπτες . Αυτό μας επιτρέπει να κρίνουμε την
      αποτελεσματικότητα των συγκεκριμένων τρόπων επικοινωνίας και τη συνολική
      αποτελεσματικότητα των εκστρατειών του μάρκετινγκ μας . Η πληροφορία αυτή
      δεν χρησιμοποιείται σε προσωπικό επίπεδο, αλλά για να καταλάβουμε πώς
      μπορούμε να βελτιώσουμε τα emails μας.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΕΦΑΡΜΟΣΤΕΟ ΔΙΚΑΙΟ ΚΑΙ ΛΟΙΠΟΙ ΟΡΟΙ
      </div>
      <br />
      Οι ανωτέρω όροι και προϋποθέσεις χρήσης της DigitalTv.com.cy, καθώς και
      οποιαδήποτε τροποποίηση τους, διέπονται και συμπληρώνονται από το Κυπριακό
      δίκαιο. Για οποιαδήποτε διαφορά προκύψει μεταξύ των συμβαλλομένων μερών,
      δικαιοδοσία θα έχουν τα Κυπριακά Δικαστήρια και θα εφαρμόζεται το Κυπριακό
      Δίκαιο. Οποιαδήποτε διάταξη των ανωτέρω όρων καταστεί αντίθετη προς το
      νόμο, παύει αυτοδικαίως να ισχύει και αφαιρείται από το παρόν, χωρίς σε
      καμία περίπτωση να θίγεται η ισχύς των λοιπών όρων. Το παρόν αποτελεί τη
      συνολική συμφωνία μεταξύ της DigitalTv.com.cy και του επισκέπτη/χρήστη των
      σελίδων και υπηρεσιών του και δε δεσμεύει παρά μόνο αυτούς. Καμία
      τροποποίηση των όρων αυτών δε θα λαμβάνεται υπόψη και δε θα αποτελεί τμήμα
      της συμφωνίας αυτής, εάν δεν έχει διατυπωθεί εγγράφως και δεν έχει
      ενσωματωθεί σε αυτή.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΠΡΟΣΤΑΣΙΑ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ
      </div>
      <br />
      Η εγγραφή κάθε χρήστη στις υπηρεσία/ες της DigitalTv.com.cy και η
      γνωστοποίηση των στοιχείων του στην DigitalTv.com.cy και/ ή στην Digital
      Tree Media Ltd, και/ή σε οποιουσδήποτε υπηρέτες/αντιπροσώπους αυτών
      αποτελεί ρητή συγκατάθεσή τους για τη συλλογή και επεξεργασία των
      στοιχείων αυτών από την DigitalTv.com.cy και/ προς σκοπό της εγγραφής του
      σε αυτήν. Η διαχείριση αυτή των δεδομένων θα πραγματοποιείται εντός του
      νομικού πλαισίου και υπό τις εγγυήσεις που θέτει ο ν. 138(Ι)/2001, όπως
      εξειδικεύεται και συμπληρώνεται από αποφάσεις και από κοινοτικές οδηγίες.
      Η παρούσα σύμβαση Προστασίας Προσωπικών Δεδομένων καλύπτει όλα τα
      προσωπικά δεδομένα των επισκεπτών/χρηστών/μελών που συλλέγονται κατά τη
      διάρκεια επίσκεψης και χρήσης των υπηρεσιών και των σελίδων του δικτυακού
      ιστοχώρου DigitalTv.com.cy. Επίσης, η παρούσα σύμβαση καλύπτει τις
      προϋποθέσεις συλλογής, επεξεργασίας και διαχείρισης των προσωπικών
      δεδομένων των επισκεπτών/χρηστών/μελών από την DigitalTv.com.cy προς τις
      τυχόν συνδεδεμένες εταιρείες με αυτό. Η παρούσα σύμβαση δεν καλύπτει σε
      καμία περίπτωση τη σχέση μεταξύ των επισκεπτών/χρηστών/μελών της
      DigitalTv.com.cy και οποιωνδήποτε υπηρεσιών που δεν υπόκεινται στον έλεγχο
      ή/και την ιδιοκτησία της DigitalTv.com.cy. Δεδομένης της φύσης και του
      όγκου του Διαδικτύου, υπό οποιεσδήποτε συνθήκες, συμπεριλαμβανομένης και
      της περίπτωσης αμέλειας, η DigitalTv.com.cy δεν ευθύνεται για οποιασδήποτε
      μορφής ζημία υποστεί ο επισκέπτης/χρήστης των σελίδων, υπηρεσιών, επιλογών
      και περιεχομένων της DigitalTv.com.cy στις οποίες προβαίνει με δική του
      πρωτοβουλία και με τη γνώση των όρων του παρόντος.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΣΥΛΛΟΓΗ ΚΑΙ ΧΡΗΣΗ ΔΕΔΟΜΕΝΩΝ
      </div>
      <br />
      Η DigitalTv.com.cy συλλέγει προσωπικά δεδομένα α) όταν ο
      επισκέπτης/χρήστης εγγράφεται στις υπηρεσίες της β) όταν χρησιμοποιεί τα
      προϊόντα ή/και τις υπηρεσίες της και γ) όταν επισκέπτεται τις σελίδες της
      ή/και εισέρχεται στα προωθητικά / διαφημιστικά προγράμματά της. Κατά την
      εγγραφή του επισκέπτη/χρήστη στην DigitalTv.com.cy οι ζητούμενες
      πληροφορίες είναι οι εξής : Όνομα, Επίθετο, Οδός, Αριθμός, Πόλη, Τ.Κ.,
      Τηλέφωνο, Έτος γέννησης, Επάγγελμα, Διεύθυνση ηλεκτρονικού ταχυδρομείου
      (e-mail). Η DigitalTv.com.cy χρησιμοποιεί τα προσωπικά δεδομένα των
      επισκεπτών/χρηστών/μελών για τρεις γενικούς λόγους:
      <br />
      <br />
      <div className={classes.padded}>
        <li>
          Την ενημερωτική υποστήριξη των πελατών/χρηστών της και την επιλογή του
          περιεχομένου που τους προσφέρει, ούτως ώστε να είναι συναφές με τις
          γενικές τους προτιμήσεις
        </li>

        <li>
          Την ικανοποίηση των εκάστοτε απαιτήσεων των πελατών/χρηστών της
          DigitalTv.com.cy σχετικά με τις υπηρεσίες και τα προϊόντα που
          διατίθενται από και μέσω της DigitalTv.com.cy
        </li>

        <li>
          Την ενημέρωση τους σχετικά με νέες προσφορές υπηρεσιών και προϊόντων
        </li>
      </div>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΔΙΟΡΘΩΣΗ Ή/ΚΑΙ ΔΙΑΓΡΑΦΗ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ
      </div>
      <br />
      Η DigitalTv.com.cy δίνει το δικαίωμα στους χρήστες/μέλη να διορθώσουν
      ή/και ενημερώσουν τα προσωπικά τους δεδομένα ανά πάσα χρονική στιγμή,
      επισκεπτόμενοι απλώς την σχετική υπηρεσία της DigitalTv.com.cy. Οι χρήστες
      μπορούν να ζητήσουν τη διαγραφή των προσωπικών τους στοιχείων ή/και
      αδρανοποίηση του λογαριασμού τους μέσω της φόρμας επικοινωνίας της
      DigitalTv.com.cy
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΔΙΚΑΙΩΜΑ ΠΡΟΣΒΑΣΗΣ</div>
      <br />
      Κάθε χρήστης έχει το δικαίωμα να γνωρίζει εάν δεδομένα προσωπικού
      χαρακτήρα που τον αφορούν αποτελούν αντικείμενο επεξεργασίας και μπορεί,
      εφόσον υποβάλει σχετικό αίτημα στην Εταιρεία, να λάβει εγγράφως αντίγραφο
      με τα προσωπικά του δεδομένα, ως προνοείται από τον περί Επεξεργασίας
      Δεδομένων Προσωπικού Χαρακτήρα (Προστασία του Ατόμου) Νόμο (Ν.138(Ι)/2001)
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΔΙΟΧΕΥΤΕΥΣΗ ΚΑΙ ΑΠΟΚΑΛΥΨΗ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ
      </div>
      <br />
      Η ιστοσελίδα μας δεσμεύεται να μην προβεί σε πώληση, ενοικίαση ή καθ’
      οιονδήποτε τρόπο δημοσίευση ή/και κοινοποίηση των προσωπικών δεδομένων των
      επισκεπτών/χρηστών/μελών της DigitalTv.com.cy σε κανένα τρίτο φορέα. Η
      DigitalTv.com.cy μπορεί να διοχετεύσει προσωπικά δεδομένα των
      επισκεπτών/χρηστών/μελών του σε τρίτα νομικά ή/και φυσικά πρόσωπα μόνο εάν
      <br />
      <br />
      <div className={classes.padded}>
        <li>
          Έχει τη ρητή συγκατάθεση των επισκεπτών/χρηστών/μελών για τη
          διοχέτευση προσωπικών δεδομένων
        </li>

        <li>
          Η διοχέτευση των προσωπικών δεδομένων προς νομικά ή/και φυσικά πρόσωπα
          που συνεργάζονται με την DigitalTv.com.cy καθίσταται αναγκαία για την
          υλοποίηση των επιθυμιών ή/και παραγγελιών των χρηστών/μελών. Τα νομικά
          και φυσικά πρόσωπα που συνεργάζονται με την DigitalTv.com.cy έχουν το
          δικαίωμα να επεξεργάζονται τα προσωπικά δεδομένα που οι χρήστες/μέλη
          της DigitalTv.com.cy καταθέτουν σε αυτό μόνο στο βαθμό που είναι
          απόλυτα αναγκαίος για την παροχή υποστήριξης προς την
          DigitalTv.com.cy.
        </li>

        <li>
          Επιτάσσεται λόγω συμμόρφωσης με τις σχετικές διατάξεις του νόμου και
          προς τις αρμόδιες και μόνο αρχές
        </li>
      </div>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΔΙΑΦΗΜΙΣΗ</div>
      <br />
      Ο επισκέπτης/χρήστης που ενδιαφέρεται να διαφημιστεί στην
      DigitalTv.com.cy, προκειμένου να λάβει όλες τις απαραίτητες γι' αυτήν
      πληροφορίες θα πρέπει να συμπληρώσει στη σχετική αίτηση τα εξής στοιχεία:
      Ονοματεπώνυμο/Επωνυμία Επιχείρησης-Τηλέφωνο -E-mail-URL (Ηλεκτρονική
      διεύθυνση της προς διαφήμιση ιστοσελίδας). Η DigitalTv.com.cy δεν
      ευθύνεται για την πολιτική προστασίας των προσωπικών δεδομένων που
      ακολουθούν οι διαφημιζόμενοι σε αυτό κατά τις συναλλαγές τους με τους
      επισκέπτες/χρήστες των υπηρεσιών αυτών.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΥΠΗΡΕΣΙΕΣ ΗΛΕΚΤΡΟΝΙΚΟΥ ΕΜΠΟΡΙΟΥ
      </div>
      <br />
      Για τη χρήση των υπηρεσιών ηλεκτρονικού εμπορίου της DigitalTv.com.cy από
      τον επισκέπτη/χρήστη ζητούνται τα εξής στοιχεία : Ονοματεπώνυμο/Διεύθυνση
      φυσικού προσώπου /Έδρα επιχείρησης-ΤΚ-Τηλέφωνο-E-mail-Πόλη-Χώρα-Στοιχεία
      Πιστωτικής Κάρτας. Τα ανωτέρω στοιχεία απαιτούνται και για την έκδοση των
      σχετικών παραστατικών (φορολογικών) και διατηρούνται στο οικονομικό αρχείο
      της Οffsite.com.cy. H DigitalTv.com.cy μπορεί να αξιοποιεί-επεξεργάζεται
      τα στοιχεία που συλλέγονται κατά τις συναλλαγές των χρηστών προκειμένου να
      καταγράφει τα αγοραστικά ενδιαφέροντα του συναλλασσόμενου και να προβαίνει
      σε νέες προσφορές, εκτός αν ο χρήστης/μέλος των υπηρεσιών αυτών ζητήσει να
      μη γίνονται τέτοιου είδους προσφορές. Τα στοιχεία που αφορούν τα
      αγοραστικά ενδιαφέροντα του επισκέπτη/χρήστη ουδέποτε δύνανται να
      γνωστοποιηθούν σε τρίτους εκτός των ρητά αναφερόμενων στην παρούσα σύμβαση
      εξαιρέσεων.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΣΤΟΙΧΕΙΑ ΠΙΣΤΩΤΙΚΗΣ ΚΑΡΤΑΣ
      </div>
      <br />
      Η Πιστωτική Κάρτα που ο επισκέπτης/χρήστης θα χρησιμοποιήσει για την
      εξόφληση υπηρεσιών/συνδρομών της DigitalTv.com.cy, χρεώνεται μόνο για μία
      φορά και μόνο για τη συγκεκριμένη συναλλαγή εκτός κι αν ο
      επισκέπτης/χρήστης έχει επιλέξει την αυτόματη χρέωση της πιστωτικής του
      κάρτας κάθε φορά που ανανεώνεται η συνδρομή του ή η υπηρεσία στην οποία
      συμμετέχει. H DigitalTv.com.cy δεν ευθύνεται για τους όρους χρήσης
      προσωπικών δεδομένων που υιοθετούν οι χρηματοπιστωτικοί οργανισμοί με τους
      οποίους συνεργάζεται για την ολοκλήρωση των εμπορικών συναλλαγών.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΣΥΝΟΜΙΛΙΕΣ ΣΕ ΠΡΑΓΜΑΤΙΚΟ ΧΡΟΝΟ (ONLINE)/ MESSAGE BOARDS- CHATS
      </div>
      <br />
      Η DigitalTv.com.cy μπορεί να καταγράφει το περιεχόμενο των μηνυμάτων που
      αποστέλλονται από τους επισκέπτες/χρήστες των σχετικών υπηρεσιών
      αποκλειστικά για τη διαπίστωση παραβάσεων των όρων χρήσης τους όπως
      προβλέπεται ειδικότερα στους Όρους Χρήσης και για κανέναν άλλο σκοπό. Η
      DigitalTv.com.cy δε δύναται να γνωστοποιήσει σε τρίτους το περιεχόμενο των
      σχετικών μηνυμάτων εκτός αν ο συντάκτης τους συναινεί.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        'ΔΕΣΜΟΙ' (LINKS) ΠΡΟΣ ΑΛΛΑ SITES
      </div>
      <br />
      Η DigitalTv.com.cy περιλαμβάνει links ("δεσμούς") προς άλλους δικτυακούς
      τόπους (sites) τα οποία δεν ελέγχονται από την ίδια αλλά από τους τρίτους
      φορείς (φυσικά ή νομικά πρόσωπα). Σε καμία περίπτωση δεν ευθύνεται η
      DigitalTv.com.cy για τους Όρους Προστασίας των Προσωπικών Δεδομένων των
      επισκεπτών/χρηστών τους οποίους οι φορείς αυτοί ακολουθούν.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ΣΕΛΙΔΕΣ ΧΡΗΣΤΩΝ (HOMEPAGES)
      </div>
      <br />
      Η DigitalTv.com.cy δεν έχει καμία απολύτως ευθύνη για το περιεχόμενο το
      οποίο αναρτούν ή/και δημοσιεύουν οι χρήστες/μέλη στις προσωπικές τους
      σελίδες (Homepages). Άλλοι χρήστες/μέλη επισκέπτονται τις προσωπικές αυτές
      σελίδες με δική τους ευθύνη. Εάν η DigitalTv.com.cy λάβει ειδοποίηση ότι
      το περιεχόμενο σε κάποια/ες από τις προσωπικές σελίδες που φιλοξενεί θίγει
      τρίτα πρόσωπα ή/και παραβιάζει τα προσωπικά δεδομένα τρίτων προσώπων
      διατηρεί το δικαίωμα να προβεί άμεσα και χωρίς προειδοποίηση σε διαγραφή
      των σχετικών σελίδων ή/και του χρήστη/μέλους που προέβη στη σχετική
      ανάρτηση ή/και δημοσίευση. Η παρούσα ρήτρα ισχύει για κείμενα,
      φωτογραφίες, εικόνες, ηχητικά αρχεία, αρχεία video, και οποιοδήποτε άλλο
      περιεχόμενο που μπορεί οι χρήστες/μέλη να αναρτούν/δημοσιεύουν στις
      προσωπικές τους σελίδες.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>IP ADDRESSES</div>
      <br />
      H διεύθυνση IP μέσω της οποίας ο Η/Υ του επισκέπτη/χρήστη έχει πρόσβαση
      στο Διαδίκτυο και στη συνέχεια στην DigitalTv.com.cy κρατείται για
      τεχνικούς λόγους και αξιοποιείται αποκλειστικά και μόνο για την
      συγκέντρωση στατιστικών στοιχείων.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΨΗΦΟΦΟΡΙΕΣ</div>
      <br />
      Για τη συμμετοχή του επισκέπτη/χρήστη σε ψηφοφορία που διενεργείται από
      την DigitalTv.com.cy δεν ζητούνται προσωπικά στοιχεία. Η ψήφος του
      επισκέπτη/χρήστη καταγράφεται αποκλειστικά για την εξαγωγή συμπερασμάτων
      σε σχέση με τη θέση της κοινής γνώμης πάνω σε ορισμένο ζήτημα. Οι
      ψηφοφορίες και τα αποτελέσματά τους αποτελούν πνευματική ιδιοκτησία της
      DigitalTv.com.cy.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΠΡΟΣΤΑΣΙΑ ΑΝΗΛΙΚΩΝ</div>
      <br />
      Χρήστες της DigitalTv.com.cy που είναι ανήλικοι δεν επιτρέπεται να έχουν
      πρόσβαση στις υπηρεσίες της DigitalTv.com.cy που μπορεί να θεωρούνται
      ακατάλληλες για ανηλίκους και οι οποίες δεν είναι δυνατό να ελεγχθούν από
      την DigitalTv.com. Σε κάθε ενότητα ή υπηρεσία που μπορεί να περιέχει υλικό
      ακατάλληλο/προσβλητικό/ανήθικο υπάρχει σχετική προειδοποίηση προς τους
      χρήστες. Ωστόσο, η DigitalTv.com.cy δε φέρει καμία ευθύνη εάν παρά την
      προειδοποίηση ανήλικοι χρήστες αυτοβούλως επισκεφτούν σελίδες με υλικό
      ακατάλληλο/προσβλητικό/ανήθικο το οποίο δεν είναι δυνατό να ελέγχεται
      συνεχώς.
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>ΕΦΑΡΜΟΣΤΕΟ ΔΙΚΑΙΟ</div>
      <br />
      Η διαχείριση και προστασία των προσωπικών δεδομένων του χρήστη των
      υπηρεσιών της DigitalTv.com.cy υπόκειται στους όρους της παρούσας
      πολιτικής καθώς και στις σχετικές διατάξεις του περί Επεξεργασίας
      Δεδομένων Προσωπικού Χαρακτήρα (Προστασία του Ατόμου) Νόμου
      (Ν.138(Ι)/2001). Η DigitalTv.com.cy διατηρεί το δικαίωμα αλλαγής των όρων
      προστασίας των προσωπικών δεδομένων κατόπιν ενημέρωσης των χρηστών και
      μέσα στο υπάρχον ή και ενδεχόμενο νομικό πλαίσιο. Εάν κάποιος χρήστης δεν
      συμφωνεί με τους όρους προστασίας των προσωπικών δεδομένων που
      προβλέπονται στο παρόν οφείλει να μη χρησιμοποιεί τις υπηρεσίες της
      DigitalTv.com.cy.
      <br />
      <br />
      Προσωπικά δεδομένα:
      <br />
      <br />
      Η DigitalTv.com.cy δεσμεύεται να προστατεύει τα προσωπικά δεδομένα του
      χρήστη. Θέλουμε οι υπηρεσίες μας να είναι ασφαλής σε ένα ελκυστικό
      διαδικτυακό περιβάλλον. Στόχος της DigitalTv.com.cy είναι να βοηθήσει τον
      χρήστη να καταλάβει τις πιθανές πληροφορίες που πιθανόν συλλέγονται για
      σκοπούς βελτίωσης του προϊόντος αλλά και για την εμπορική σημασία του εν
      λόγω προϊόντος.
      <br />
      <br />
      Συλλογή πληροφοριών:
      <br />
      <br />
      Τα διαδικτυακά προϊόντα της DigitalTv.com.cy ενδέχεται να συλλέγουν
      προσωπικά δεδομένα όπως email και στοιχεία από κοινωνικά δίκτυα για
      σκοπούς βελτίωσης της ενημερωτικής διαδικασίας όπως newsletters
      <br />
      <br />
      Παραχώρηση δικαιωμάτων οπτικού, αρχειακού περιεχομένου:
      <br />
      <br />Η χρήση εφαρμογής για συλλογή και δημοσίευση ειδησεογραφικού υλικού
      που παρέχεται από τους χρήστες του εν λόγω προϊόντος προϋποθέτει ότι ο
      κάθε χρήστης είναι υπεύθυνος για την έγκυρη πληροφόρηση αλλά και
      διασφάλιση του δικαιώματος ότι του ανήκει το περιεχόμενο του υλικού που
      αποστέλλει μέσω της εφαρμογής. Τα πνευματικά δικαιώματα του υλικού που
      αποστέλλονται από τον χρήστη στην εφαρμογή μεταφέρονται στην
      DigitalTv.com.cy και αφαιρείτε το δικαίωμα από τον χρήστη να τα
      διεκδικήσει πίσω. Ο χρήστης υπεύθυνα αποστέλλει το υλικό μέσω της
      εφαρμογής γνωρίζοντας ότι απαγορεύεται η κάθε μορφή παραποίησης που να
      υποδεικνύει παραπληροφόρηση.
    </div>
  );
};

export default TOS;
