// import { useMyContext } from "../../contexts/StateHolder";
// import { useEffect, useState } from "react";
// import settings from "../../configs/config_settings.json";
// import { useCookies } from "react-cookie";
// import { useMyContextFunctions } from "../../contexts/ContextFunctions";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import * as classes from "./TVGuide.module.css";
import TVGuideEventList from "./TVGuideEventList";
// import { getAllEvents } from "../../scripts/dataHandlers";
import Carousel from 'react-multi-carousel';
import useWindowDimensions from '../WindowDimension';
// import RenderItem from '../ViewAssets/RenderItems/RenderItem';

export default function TVGuideChannelList(props) {
    const windowDimension = useWindowDimensions();

    // Destructure props.settings
    const {
        // id,
        // routes,
        slickSettings,
        // itemImageComponent,
        // maintainImageAspectRatio,
    } = props.settings;

    let className1 = '';

    if (props.channels?.length > 0 && slickSettings) {
        let arr = Object.keys(slickSettings.responsive).map(
            (k) => slickSettings.responsive[k]
        );
        let arr1 = [...arr];

        let j = [...arr1].find(
            (el) =>
                el.breakpoint.min <= windowDimension.width &&
                el.breakpoint.max >= windowDimension.width
        );

        if (props.channels?.length === j.items) {
            className1 = 'addSeecondary';
        } else {
            className1 = '';
        }
    }

    // const placeholderItem = {
    //     id: '123456789087654321',
    //     name: 'placeholderItem',
    // };

    // const placeHolderArray = [
    //     placeholderItem,
    //     placeholderItem,
    //     placeholderItem,
    //     placeholderItem,
    //     placeholderItem,
    //     placeholderItem,
    //     placeholderItem,
    // ];

    return (
        // <div className={`${className1}`}>
        //     {props.channels?.length > 0 ? (
        //         <Carousel {...slickSettings}>
        //             {props.channels.map((channel, index) => {
        //                 return (
        //                     <TVGuideEventList key={index} channel={channel} from={props.from} to={props.to} />
        //                 );
        //             })}
        //         </Carousel>
        //     ) : (
        //         <Carousel {...slickSettings}>
        //             {placeHolderArray.map((channel, index) => {
        //                 return (
        //                     <TVGuideEventList
        //                         key={index}
        //                         channel={channel}
        //                         from={props.from}
        //                         to={props.to}
        //                     />
        //                 );
        //             })}
        //         </Carousel>
        //     )}
        // </div>

        <div className={`${className1}`}>
            {props.channels?.length > 0 && (
                <Carousel {...slickSettings}>
                    {props.channels.map((channel, index) => {

                        return (
                            <TVGuideEventList
                                key={index}
                                channel={channel}
                                from={props.from}
                                to={props.to}
                            />
                        );
                    })}
                </Carousel>
            )}
        </div>
    );
}
