import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as classes from "./TVGuide.module.css";
import img2 from "../../images/right.png"
import img1 from "../../images/left.png"
import { getImageByKey } from "../../scripts/getImageByKey"
 
function SampleNextArrow(props) {
    const { className, onClick, increase,liveChannelPage, index } = props;
    return (
        <>
        <div
            onClick={() => {
                if(index == 21) return
                else{
                    increase()
                    onClick()
                }
            }}
            className={classes.arrowNext}
        >
            <img src={img2} alt="" />
            {/* <img className={className} src={getImageByKey('right_arrow_yellow')} alt="right_arrow" style={{ width: 'auto' }} /> */}
        </div>
        {!liveChannelPage &&  <div
            onClick={() => {
                if(index == 21) return
                else{
                    increase()
                    onClick()
                }
            }}
            className={classes.arrowNext1}
        >
            <img src={img2} alt="" />
            {/* <img className={className} src={getImageByKey('right_arrow_yellow')} alt="right_arrow" style={{ width: 'auto' }} /> */}
        </div> }
      
        </>
    );
}
 
function SamplePrevArrow(props) {
    const { className, onClick, decrease,liveChannelPage, index } = props;
    return (
        <>
        <div
            onClick={() => {
                if(index == 7) return
                else{
                    decrease()
                    onClick()
                }
            }}
            className={classes.arrowPrev}
        >
          <img src={img1} alt="" />
        </div>
        {!liveChannelPage &&  <div
            onClick={() => {
                if(index == 7) return
                else{
                    decrease()
                    onClick()
                }
            }}
            className={classes.arrowPrev1}
        >
          <img src={img1} alt="" />
        </div>}
      
        </>
    );
}
 
export default function TVGuideHeader({ setFrom, setTo, toShow,liveChannelPage}) {
    const today = new Date();
    const [dateArr, setDateArr] = useState([])
    const sliderRef = useRef();
    const [index, setIndex] = useState(14);
    
    useEffect(() => {
        let dateArray = [];
        let start_date = new Date(today.setDate(today.getDate() - 8));
        for (let i = 1; i < 16; i++) {
            let day = new Date(start_date.setDate(start_date.getDate() + 1));
            day = day.toLocaleDateString();
            dateArray.push(day)
        }
        setDateArr(dateArray);
   
        //set "from" and "to" value for today
        const d = new Date();
        let start = d.setUTCHours(0, 0, 0, 0); // start time of today
        let end = d.setUTCHours(23, 59, 59, 999); // end time of today
        setFrom(start)
        setTo(end)
    }, [setTo, setFrom])
 
    // set "from" and "to" when changing the date
    useEffect(() => {
        const cd = new Date();
        let qqq = new Date(cd.setDate(cd.getDate() - (14 - index)));
        let start = qqq.setUTCHours(0, 0, 0, 0); // start time of select day
        let end = qqq.setUTCHours(23, 59, 59, 999); // end time of select day
        setFrom(start)
        setTo(end)
    }, [index, setFrom, setTo])
 
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        initialSlide: 7,
        responsive: [{
            breakpoint: 1023,
            settings: {
              slidesToShow: (liveChannelPage ? 1 : 3),
            //   infinite: true
            }
       
          }],
        slidesToShow: toShow,
        slidesToScroll: 1,
        centerMode:true,
        centerPadding:'0px',
        nextArrow: <SampleNextArrow liveChannelPage={liveChannelPage} increase={() => setIndex(index + 1)} index={index}/>,
        prevArrow: <SamplePrevArrow liveChannelPage={liveChannelPage} decrease={() => setIndex(index - 1)} index={index}/>
    };
   
    return (
     
            <Slider {...settings} ref={sliderRef}>
                {dateArr.map((day, index, dateArr) => {
                    return (
                        <div className={classes.WhatDate} key={index} style={{display: "flex"}}>
                            {/* {showExtraDates && (
                                <div className={classes.previousDate}>{dateArr[index-2] == new Date().toLocaleDateString() ? <h3 >What's On Today</h3> : <h3>{dateArr[index-2]}</h3>}</div>
                            )} */}
                            {liveChannelPage && <div className={classes.previousDate}>{dateArr[index-1] == new Date().toLocaleDateString() ? <h4 >What's On Today</h4> : <h4>{dateArr[index-1]}</h4>}</div>} 

                            <div className={classes.todaysGoal}>
                                {day === new Date().toLocaleDateString() && <h3 className={classes.TVGuideHeaderTitle}>What's On Today</h3>}
                                {day !== new Date().toLocaleDateString() && <h3 className={classes.TVGuideHeaderTitle}>{dateArr[index]}</h3>}
                            </div>
                           {liveChannelPage && <div className={classes.dateEnd}>{dateArr[index+1] == new Date().toLocaleDateString() ? <h4>What's On Today</h4> : <h4>{dateArr[index+1]}</h4>}</div>}
                            {/* {showExtraDates && (
                                <div className={classes.dateEnd}>{dateArr[index+2] == new Date().toLocaleDateString() ? <h3>What's On Today</h3> : <h3>{dateArr[index+2]}</h3>}</div>
                            )} */}
                           
                        </div>
                    )
                })}
            </Slider>
    );
}
