import { useHistory } from 'react-router-dom';
import * as classes from './CategoryTitle2.module.css';
import { useMyContext } from '../../../../contexts/StateHolder';
import { useTranslation } from 'react-i18next';

const CategoryTitle2 = (props) => {
  const history = useHistory();
  const { setChosenCategory, } = useMyContext();

  const { t } = useTranslation();

  const clickCategory = async (categoryObj) => {
    if (props?.routes?.viewAllRoute && props?.routes?.viewAllRoute !== '') {
      history.push(`AllShows/${props.title}`);
    }
    else if (props.routes.categories) {
      history.push(`/AllShows/${props.routes.categories}/${props.title.replace(' ', '')}/${props.id}`);
    }
    else {
      setChosenCategory(categoryObj);
      history.push(`/${props.routes.categories}/${categoryObj.id}`);
    }
  };

  return (
   <div className={classes.sliderContainer}>
     <div className={classes.CategoryItem_main}>
      <div className={`${classes.CategoryItem_main_info} font-500 $`}>
        <div
          className={`${classes.CategoryItem_main_info_title} `}
        >
          <div className={classes.categoryitem_title_innerWrapper}>
            {props.showTitle !== false ? props.title : null}
          </div>
        </div>

        {/* We will only show View All when their is some assets */}
        {props?.item?.length > 0 && (
          <div
            className={`${classes.CategoryItem_main_link} font-200`}
            onClick={() =>
              props.id
                ? clickCategory({ id: props.id, title: props.title })
                : () => false
            }
          >
            {t('categoryTitle.viewAllButton')}
          </div>
        )}

        {/* // for Serie Asset */}
        {props.showSerieViewAll && (
          <div
            className={`${classes.CategoryItem_main_link} font-200`}
            onClick={() =>
              props.id
                ? clickCategory({ id: props.id, title: props.title })
                : () => false
            }
          >
            {t('categoryTitle.viewAllButton')}
          </div>
        )}
      </div>
    </div>
   </div>
  );
};

export default CategoryTitle2;
