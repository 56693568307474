import { useMyContext } from "../../../contexts/StateHolder";
import { useHistory } from "react-router-dom";
import ItemTitle from "./ItemTitle";
import ItemImage from "./ItemImage";
import ItemImage1 from "./ItemImage1/ItemImage1";
import ItemTitle1 from "./ItemTitle1/ItemTitle1";
import Loader from "react-loader-spinner";
import React, { useState, useEffect } from "react";
import { getAllEvents } from "../../../scripts/dataHandlers";
import { setUncaughtExceptionCaptureCallback } from "process";

//import ReactGA from 'react-ga';
//import { googleAnalytics } from '../configs/config_settings.json';

// List of usable item title and image components
const components = {
    ItemTitle,
    ItemImage,
    ItemImage1,
    ItemTitle1,
};

// Renders props item
const RenderItem = (props) => {
    if (props.isCat) {
        console.log(`props`, props);
    }
    const { withBackground,className } = props;
    
    const history = useHistory();
    //console.log(props, props.routes);
    // Bring stateholders from context
    const { setChosenCategory, setChosenItem, setisSerie, } = useMyContext();

    let [startTime, setStartTime] = useState('')
    let [endTime, setEndTime] = useState('')
    let [currentEventName, setCurrentEventName] = useState('')

    const clickItem = (item) => {
        // Set chosenItem
        // console.log("chosenItem=>", item);
        setChosenItem(item);

        // When item is clicked, set chosen category
        setChosenCategory({ id: item.groupItemIds, title: item.groups });

        /*
    if(googleAnalytics !== ""){
    // Report GA about clicked asset
    ReactGA.event({
      category: "Asset",
      action: `Clicked asset ${item.id} from ${window.location.pathname}`
    }
    */

        // Push user to route, which is defined in main component

        // defining whether it is serie ir not

        props.isSerie ? setisSerie(true) : setisSerie(false);

        // if (props.toggleVideoPlayer) {
        //     setShowPlayer("hidden");
        // }
        props.setEpisodeNumber && props.setEpisodeNumber(props.index+1)

        // Absolute or relative path -checker
        const urlRegExp = /^https?:\/\//i;
        if (urlRegExp.test(props.pushRoute)) {
            // Is absolute path, redirect user to location
            window.location.href = props.pushRoute;
        } else {
            // Is relative path, push to relative path
            history.push(props.pushRoute);
        }
    };

    // TODO: Pick image format based on config-file settings

    // Choose child component for image
    const ItemImageComponent = components[props.itemImageComponent];
    // Choose child component for itemTitle
    let ItemTitleComponent;

    if (props.itemTitleComponent) {
        ItemTitleComponent = components[props.itemTitleComponent];
    }

    const d = new Date();
    let start = d.setUTCHours(0, 0, 0, 0); // start time of today
    let end = d.setUTCHours(23, 59, 59, 999); // end time of today

    useEffect(() => {
        const fetchAllEventsData = async () => {
            const res = await getAllEvents(196288014, props.serviceId, start, end);
            res?.data.events && res?.data.events.map(e => {
                let st = new Date(e.info.startTime);
                st = st.getTime()
                let et = st + e.info.duration * 1000;
                let stDate = new Date(st);
                let stHours = "" + stDate.getHours();
                let stMins = "" + stDate.getMinutes();
                let etDate = new Date(et);
                let etHours = "" + etDate.getHours();
                let etMins = "" + etDate.getMinutes();

                while (stHours.length < 2) {
                    stHours = "0" + stHours;
                }
                while (stMins.length < 2) {
                    stMins = "0" + stMins;
                }
                while (etHours.length < 2) {
                    etHours = "0" + etHours;
                }
                while (etMins.length < 2) {
                    etMins = "0" + etMins;
                }

                let localStartTime = stHours + ":" + stMins;
                let localEndTime = etHours + ":" + etMins;
                if (st <= currentTime && currentTime <= et) {
                    setCurrentEventName(e.description.en_US.name)
                    setStartTime(localStartTime)
                    setEndTime(localEndTime)
                }
                return null
            });
        }

        fetchAllEventsData();
        return () => {
            setCurrentEventName(null)
            setStartTime(null)
            setEndTime(null)
        }
    }, [props.serviceId, start, end])

    let currentTime = new Date();
    currentTime = currentTime.getTime();

    // console.log(props.liveChannel);

    return props.item.name !== "placeholderItem"  ? (
        <div className={`categoryItem ${props.className}`} key={props.item.id} onClick={() => clickItem(props.item)}>
       
            <ItemImageComponent
                item={props.item}
                imageType={props.imageType}
                imageChannel={props.imageChannel}
                showPlayIcon={props.showPlayIcon}
                hidePlayButton={props.hidePlayButton}
                playIconSize="2x"
                // aspectRatio
                maintainImageAspectRatio={props.maintainImageAspectRatio}
            />
            {props.itemTitleComponent && (
                <ItemTitleComponent
                    withBackground={withBackground}
                    item={props.item}
                    showCategoryName={props.showCategoryName}
                    showReleaseYear={props.showReleaseYear}
                    showDuration={props.showDuration}
                    showStatus={props.showStatus}
                    textStyle={props.textStyle}
                    showPublishedDate={props.showPublishedDate}
                    hideDescription={props.hideDescription}
                    showOnNowDetails={props.showOnNowDetails}
                    currentEventName={currentEventName}
                    startTime={startTime}
                    endTime={endTime}
                    showTitle={props.showTitle}
                    liveChannel={props.liveChannel}
                />
            )}
        </div>
    ) : (
        <div className={"categoryItemPlaceholder"} key={props.item.id}>
            <Loader
                type="TailSpin"
                color="#dfdfdf"
                height={50}
                width={50}
                timeout={3000} //3 secs
            />
        </div>
    );
};

export default RenderItem;