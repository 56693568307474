import React from "react";
import { getImageByKey } from "../../scripts/getImageByKey";
import * as classes from "./Poster.module.css";

const AddPoster = () => {
    return (
        <div className={classes.PosterContent}>
            <a href="https://www.mytvbroadcasting.my/">
                <img src={getImageByKey("poster")} alt="" />
            </a>
        </div>
    );
};

export default AddPoster;
