import { useMyContext } from "../../contexts/StateHolder";
import { useEffect, useState } from "react";
import settings from "../../configs/config_settings.json";
import { useHistory, useLocation } from "react-router-dom";
import * as classes from "./ChannelPlayer.module.css";
import { useTranslation } from "react-i18next";
import { getAllEvents, getChannels } from "../../scripts/dataHandlers";
//components
import EventInfoTab from "./EventInfoTab";
import LiveChannels from "../ViewAssets/Sliders/Series/LiveChannels/LiveChannels";
import LiveVideoPlayer from "../VideoPlayer/LiveVideoPlayer";
import { useParams } from 'react-router-dom';
import TVGuideHeader from '../TVGuide/TVGuideHeader'
import playerCover from './playerCover.png';

export default function ChannelOne() {
    const { lcId = 196291301, organizationId, setLcId, setOrganizationId } = useMyContext();
    let { lcId: channelID } = useParams();
    let [channels, setChannels] = useState();
    const [currentEventName, setCurrentEventName] = useState("MYTV");
    // const [currentEventDesc, setCurrentEventDesc] = useState("");

    // set organizationId, set lcId
    const history = useHistory();
    const location = useLocation();
    const url = history.location.pathname;
    const myArray = url.split("/");

    useEffect(() => {
        setLcId(myArray[3]);
        setOrganizationId(myArray[2]);
    }, [url]);

    useEffect(() => {
        // console.log("history", history);
        // FOR AVOIDING RE-RENDER HACK
        if (history.action === "PUSH") {
            window.location.reload();
        }
    }, [history]);

    //fetch all event list for one channel
    const d = new Date();

    // let month = d.getMonth() + 1;
    // let day = d.getDate();
    // let year = d.getFullYear();
    // let current_now = day + "/" + month + "/" + year;

    //let current_time = d.toISOString().slice(11, 19);

    // Local time
    let h = "" + d.getHours();
    let m = "" + d.getMinutes();
    let s = "" + d.getSeconds();

    while (h.length < 2) {
        h = "0" + h;
    }
    while (m.length < 2) {
        m = "0" + m;
    }
    while (s.length < 2) {
        s = "0" + s;
    }

    // let current_time = "" + h + ":" + m + ":" + s;

    let current_day = d.getDay(); // day of today
    let start = d.setUTCHours(0, 0, 0, 0); // start time of today
    let end = d.setUTCHours(23, 59, 59, 999); // end time of today

    const [items, setItems] = useState();
    const [open, setOpen] = useState(current_day);
    const [from, setFrom] = useState(start);
    const [to, setTo] = useState(end);
    const [date, setDate] = useState(null)

    useEffect(() => {
        const fetchAllEventsData = async () => {
            const res = await getAllEvents(organizationId, lcId, from, to);
            setItems(res?.data.events);
        };

        fetchAllEventsData();

        const fetchLiveChannelData = async () => {
            const res = await getChannels(196288014);
            // console.log("all Channels=>", res);
            setChannels(res?.data.channels)
        };

        fetchLiveChannelData();
    }, [open, lcId, organizationId, from, to]);

    //
    const { t } = useTranslation();
    let channelName = 'TVAH';

    if (channels) {
        channels.map(e => {
            if (e.serviceId == channelID) channelName = e.title
        })
    }
    const autoPlay = location && location.state && location.state.autoPlay;
    console.log(location, 'autoPlay')
    return (
        <div className="maxContainerPrimary channelsLive">
            <div className={classes.ChannelContainer}>
                <div className={classes.ChannelPlayer}>
                    {currentEventName && (
                        autoPlay ?
                            <LiveVideoPlayer autoplay={true} /> :
                            <div onClick={() => {
                                history.push(`/liveChannels/196288014/${lcId}`, { autoPlay: true })
                            }}>
                                <img src={playerCover} alt="Player Cover" />
                            </div>
                    )}
                </div>
                <div className={classes.ChannelDayInfo}>
                    <div className={classes.ChannelItemInfoWrapper}>
                        <div className={classes.ChannelItemTitle}>{currentEventName}</div>
                        <div className={classes.TvStatus} style={{ color: "white" }}>
                            <span>{channelName}</span>
                            <span> - LIVE CHANNEL</span>
                            <span> - NOW PLAYING</span>
                        </div>
                        {/* <div className={classes.ChannelItemDesc}>{currentEventDesc}</div> */}
                        {/* <div className={classes.ChannelItemInfo}>
                            <p>{current_now}</p>
                            <p>{current_time}</p>
                        </div> */}
                    </div>
                    <div className={classes.channelDateBar}>
                        <TVGuideHeader liveChannelPage={true} toShow={1} setFrom={setFrom} setTo={setTo} setDate={setDate} />
                    </div>
                    {organizationId && (
                        <EventInfoTab
                            items={items}
                            open={open}
                            setOpen={setOpen}
                            setFrom={setFrom}
                            setTo={setTo}
                            setCurrentEventName={setCurrentEventName}
                            channelName={channelName}
                        // setCurrentEventDesc={setCurrentEventDesc}
                        />
                    )}
                </div>
            </div>
            <div className={classes.liveChannelsWrapper}>
                <LiveChannels
                    settings={settings.components.frontPageCategories_03}
                    showDuration={false}
                    showReleaseYear={true}
                    showPublishedDate={true}
                    hideDescription={true}
                    latestData={true}
                    titleName={t("seriesSlider.onnow")}
                    showTitle={false}
                    showOnNowDetails={true}
                />
            </div>
        </div>
    );
}
