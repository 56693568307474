import React from "react";
import { useEffect, useState } from "react";
import {
    //   getLatestAssets,
    getAssets,
} from "../../scripts/dataHandlers";
import { createToken } from "../../scripts/utils";
import { useMyContext } from "../../contexts/StateHolder";
import RenderItem from "../ViewAssets/RenderItems/RenderItem";
import * as classes from "./MoviesList.module.css";
import HeroBanner from "../ViewAssets/Sliders/HeroBanner";

export default function MoviesList(props) {
    const { organizationId, key, language, allCategories } = useMyContext();

    const {
        // groupItemId,
        slickSettings,
        routes,
        itemImageComponent,
        itemTitleComponent,
    } = props.settings;

    const [items, setItems] = useState(null);
    const [filterItems, setFilterItems] = useState(null);

    useEffect(() => {
        let token;
        let idString = "";

        // allCategories && allCategories.map(e => {
        //   if(props.listType === "MYTV"){
        //     idString = 199380201;
        //   }
        //   if(props.listType === "MOVIES"){
        //     if(e.title === props.listType) idString = e.id;
        //   }
        // })

        const fetchData = async () => {
            const res = await getAssets(organizationId, 196308901, token, language);
            // console.log("assets", res)
            setItems(res);
            res &&
                setFilterItems(
                    res.filter((e, index) => {
                        return index < 4 && e;
                    })
                );
        };

        if (organizationId && language) {
            token = createToken(organizationId, 196308901, key);

            if (token) {
                fetchData();
            }
        }
    }, [key, language, organizationId]);

    return (
        <>
            {/* {items && items.length > 0 && <div  className="MoviesBanner"><HeroBanner slickSettings={slickSettings} bannerItems={items} /></div>} */}
            <div className="categoriesContainermain">
                <div className={classes.categoryMain}>
                    {filterItems && filterItems.length > 0 && (
                        <div className={`${classes.ListAllCategories} font-500`}>
                            {filterItems.map((item) => {
                                let pushRoute = "";
                                pushRoute = `/playVideo/${organizationId}/${item.id}`;

                                return (
                                    <RenderItem
                                        key={`${item.id}`}
                                        item={item}
                                        pushRoute={pushRoute}
                                        className={classes.categoryItems}
                                        routes={routes}
                                        itemImageComponent={itemImageComponent}
                                        itemTitleComponent={itemTitleComponent}
                                        imageType={props.imageType}
                                        textStyle={{ textAlign: "center" }}
                                        renderCategory={true}
                                        // styles={styles}
                                        showPlayIcon={true}
                                        hidePlayButton={false}
                                        hideDescription={true}
                                        showTitle={true}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
