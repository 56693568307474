// import { useMyContext } from "../../contexts/StateHolder";
import { useEffect, useState } from "react";
// import settings from "../../configs/config_settings.json";
// import { useCookies } from "react-cookie";
// import { useMyContextFunctions } from "../../contexts/ContextFunctions";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import playLogo from "../../images/play-circle.png"
import * as classes from "./TVGuide.module.css";
// import Loader from "react-loader-spinner";
import downArrow from '../../images/Dropdown.png';
import OutsideClickHandler from 'react-outside-click-handler';
import { useHistory } from "react-router-dom";
import { useMyContext } from "../../contexts/StateHolder";

export default function TVGuideEventItem(props) {
    const {
        description,
        info
    } = props.event

    const [open, setOpen] = useState(false);
    let showValue = false;
    let hidePlayIcon = false;
    const history = useHistory();

    const {setChosenItem} = useMyContext();
    // These are in UTC
    // let start_Time = info.startTime.slice(11, 16);

    let st = new Date(info.startTime).getTime()
    let et = st + info.duration * 1000;
    // let end_Time = new Date((et)).toISOString().slice(11, 16);

    let ct = new Date().getTime(); //current time
    // console.log("start_Time, st, et, end_Time, ct, description", start_Time, st, et, end_Time, ct, description.en_US.name);

    // Local time
    let stDate = new Date(st);
    let etDate = new Date(et);
    let stHours = "" + stDate.getHours();
    let stMins = "" + stDate.getMinutes();
    let etHours = "" + etDate.getHours();
    let etMins = "" + etDate.getMinutes();

    while (stHours.length < 2) {
        stHours = "0" + stHours;
    }
    while (stMins.length < 2) {
        stMins = "0" + stMins;
    }
    while (etHours.length < 2) {
        etHours = "0" + etHours;
    }
    while (etMins.length < 2) {
        etMins = "0" + etMins;
    }

    // console.log("stHours, stMins, etHours, etMins", stHours, stMins, etHours, etMins);

    let localStartTime = stHours + ":" + stMins;
    let localEndTime = etHours + ":" + etMins;

    if (props.currentEventId == props.event.eventId) {
        showValue = true
    }
    if(st>=ct){
        hidePlayIcon = true
    }

    useEffect(() => {
        if (st <= ct && ct <= et) {
            props.setCurrentEvent(props.event)
        }
    }, [props.from, props.to, ct, et, st, props])

    function playButtonClickHandle(){
        setChosenItem(props.event)
        history.push(`/playCatchUpVideo?title=${props.event.description.en_US.name}&startTime=${props.event.info.startTime}&duration=${props.event.info.duration}&channelName=${props.channelName}`)
    }

    return (
        <>
            {true && (
                <div className={`${classes.TVGuideEventItemWrapper} ${open && classes.TVGuideBackColor}`} style={{background: showValue && "#666666"}}>
                        <div
                            onClick={() => setOpen(!open)}
                            className={`${classes.TVGuideEventItemTitle} ${open && classes.tvGuideTitle}`}
                        >
                            <div className={classes.TVGuideEventItemTime}>{localStartTime}</div>
                            <div className={`${open && classes.textLong} ${classes.TVGuideEventItemText}`}>
                                <h6>{description.en_US.name}</h6>
                                {open && <p>{description.en_US.shortDescription}</p>}
                                {open && (
                                    <div className={hidePlayIcon ? classes.HideWatchReplay : classes.watchReply} onClick={() => playButtonClickHandle()}> 
                                        <img className={classes.PlayImg} src={playLogo} alt="" /><h4>Watch Replay</h4>
                                    </div>
                                )}

                            </div>
                            {!open && <span className={hidePlayIcon ? classes.HidePlayButton : ''} type='button' onClick={() => playButtonClickHandle()}>
                                        <img  className={classes.PlayImg} src={playLogo} alt="" />
                                        </span>}
                            {/* <span className={classes.downArrow}>
                                <img src={downArrow} alt=''/>
                            </span> */}
                        </div>
                    {/* {open && (
                        <div className={classes.TVGuideEventItemContent}>
                            <div className={classes.TVGuideEventItemtext1} onClick={() => setOpen(false)}>
                                <div className={classes.TVGuideEventItemTime}>{localStartTime + "-" + localEndTime}</div>
                                <div className={classes.TVGuideEventItemText} >{description.en_US.name}</div>
                                <span style={{marginLeft: "20px", fontSize: "20px"}} >^</span>
                            </div>
                            <div className={classes.TVGuideEventDescription}>
                                <p>{description.en_US.shortDescription}</p>
                            </div>
                            <button type='button' onClick={() => playButtonClickHandle()}>Play</button>
                        </div>
                    )} */}
                </div>
            )}
        </>
    );
}