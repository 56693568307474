import * as classes from './CookiesPolicy.module.css';
//import { useTranslation } from 'react-i18next';

const CookiesPolicy = (props) => {
  //const { t } = useTranslation();

  return (
    <div
      className={`${classes.cookiesPolicyContainer} font-300`}
      style={props?.styles?.cookiesPolicyContaine}
    >
      <div className={`${classes.cookiesPolicyMainTitle} font-600`}>
        {'Cookies'}
      </div>
      <br />
      Για να διευκολύνουμε τη χρήση του ιστοτόπου μας, χρησιμοποιούμε «cookies». Τα cookies είναι μικρά αρχεία δεδομένων/κειμένου που αποθηκεύονται στον σκληρό δίσκο του υπολογιστή σας από το πρόγραμμα περιήγησής σας και τα οποία είναι απαραίτητα για τη χρήση του ιστοτόπου μας. Χρησιμοποιούμε cookies για να κατανοήσουμε καλύτερα πώς χρησιμοποιείται ο ιστότοπός μας και να προσφέρουμε καλύτερη πλοήγηση. Τα cookies μάς βοηθούν, για παράδειγμα, να καθορίσουμε αν έχετε ήδη επισκεφθεί μια σελίδα στον ιστότοπό μας. Τα cookies μπορούν επίσης να μας πουν αν έχετε επισκεφθεί τον ιστότοπό μας στο παρελθόν ή αν είστε νέος επισκέπτης. Εάν δεν επιθυμείτε την συλλογή πληροφοριών μέσω cookies, παρακαλούμε ρυθμίστε το πρόγραμμα περιήγησης διαδικτύου για να διαγράψετε όλα τα υφιστάμενα cookies από τον σκληρό δίσκο του υπολογιστή σας, να μπλοκάρετε όλα τα cookies στο μέλλον και να λαμβάνετε προειδοποίηση προτού αποθηκευτεί ένα cookie.
      <br />
      <br />
      Τα cookies που χρησιμοποιούμε χωρίζονται σε τέσσερις βασικές κατηγορίες:
      <br />
      <br />
      <table className={classes.cookiesPolicyTable}>
        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Strictly Necessary cookies
            </div>
            <div>(Cookies απόδοσης και ανάλυσης)</div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            Μας επιτρέπουν να αναγνωρίζουμε και να αναλύουμε τον αριθμό των επισκεπτών των διαδικτυακών μας τόπων και τον τρόπο που περιηγούνται μέσα σε αυτό. Τα στοιχεία αυτά τα χρησιμοποιούμε για να βελτιώσουμε τον τρόπο λειτουργίας των ιστοσελίδων με σκοπό την ευκολότερη πρόσβαση των χρηστών στο περιεχόμενο.
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Functionality cookies
            </div>
            <div>(Cookies Λειτουργικότητας)</div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            Αυτά τα cookies χρησιμοποιούνται για να σας παρέχουμε μέσω τρίτων ειδικές λειτουργίες μέσα στην ιστοσελίδα όπως ειδοποιήσεις, κοινοποιήσεις σε κοινωνικά δίκτυα και περισσότερο περιεχόμενο ανάλογο των ενδιαφερόντων σας.
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Ad Serving and Targeting
            </div>
            <div>(Cookies στόχευσης κοινού και διαφήμισης)</div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            Με αυτά τα cookies καταγράφουμε τις επισκέψεις σας στις ιστοσελίδας μας και τους συνδέσμους που ακολουθήσατε. Τις πληροφορίες χρησιμοποιούμε προκειμένου να σας εμφανίσουμε διαφημίσεις πιο σχετικές με τα ενδιαφέροντά σας. Τις πληροφορίες αυτές ενδέχεται να μοιραζόμαστε με τρίτους για τον σκοπό αυτό.
          </td>
        </tr>
      </table>

      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}> Χρήση των cookies για online διαφήμιση</div>
      <br />
      Διατηρούμε το δικαίωμα να χρησιμοποιούμε, σύμφωνα με την κείμενη νομοθεσία, τις πληροφορίες που λάβαμε μέσω των cookies και μέσω της  ανάλυσης της  συμπεριφοράς χρήσης των ιστοτόπων μας από τους επισκέπτες αυτών, για να σας εμφανίσουμε συγκεκριμένες διαφημίσεις για ορισμένα από τα προϊόντα στους ιστοτόπους μας. Πιστεύουμε ότι εσείς ως χρήστης θα επωφεληθείτε από αυτό, διότι θα σας δείξουμε διαφημίσεις ή υλικό το οποίο, σύμφωνα με τη online συμπεριφορά, θεωρούμε ότι ταιριάζει με τα ενδιαφέροντά σας, και συνεπώς θα βλέπετε λιγότερες διάσπαρτες τυχαίες διαφημίσεις ή υλικό που μπορεί να μην σας ενδιαφέρει ή να σας ενδιαφέρει λιγότερο.
      <br />
      <br />
      To cookie αποθηκεύεται στον υπολογιστή, στο κινητό η στο tablet σας όταν επισκέπτεστε τις σελίδες μας και αποθηκεύει πληροφορίες σχετικά με:
      <br />
      <br />
      <ol>
        <li>
        τη διαδικτυακή σας συμπεριφορά και τις προτιμήσεις σας προκειμένου να σας αποσταλεί (από εμάς ή συνεργάτες μας, ή διαφημιζόμενους ή/και χορηγούς μας) στοχευμένη διαφήμιση και προσφορές που ανταποκρίνονται στις προτιμήσεις σας. Οι διαφημίσεις αυτές θα εξακολουθούν να παρουσιάζονται όταν επισκέπτεστε Διαδικτυακό Τόπο που ταιριάζει με τα ενδιαφέροντά σας (targeting cookies)
        </li>
        <li>
        τις σελίδες που επισκέπτεστε στο Διαδικτυακό Τόπο, τη συσκευή, το είδος προγράμματος περιήγησης, του παρόχου υπηρεσίας διαδικτύου (ISP) και τυχόν σφάλματα που προκύπτουν από τη χρήση των υπηρεσιών, καθώς και ανάλυση της χρήσης των Διαδικτυακών Τόπων (performance cookies)
        </li>
        <li>
        τη χρήση μέσων κοινωνικής δικτύωσης, βάσει της γεωγραφικής σας θέσης ή με την αποθήκευση του user name, αν χρειάζεται, ώστε να μη χρειάζεται να το καταχωρήσετε από ξανά κατά την επιστροφή σας στην σελίδα (functional cookies).
        </li>
      </ol>
      <br />
      <br />
      Οι ιστότοποι της εταιρείας Digital Tree Media κάνουν χρήση των cookies κυρίως με σκοπό την βελτίωση της εμπειρίας του χρήστη και την παροχή υψηλή ποιότητας υπηρεσιών προς αυτούς.
      <br />
      <br />

      <div className={`${classes.cookiesPolicyTitle} font-400`}>γ. Πώς μπορείτε να αποτρέψετε την αποθήκευση των cookies στον σκληρό σας δίσκο  ή/και να τα διαγράψετε</div>
      <br />
      Δεν είστε υποχρεωμένοι να δέχεστε τα cookies, συνεπώς μπορείτε να τροποποιήσετε το πρόγραμμα περιήγησης κατά τρόπο που δεν θα δέχεται cookies. Το πρόγραμμα περιήγησης σας επιτρέπει ακόμα να δείτε τα cookies που έχετε αλλά και να ελέγχετε τη χρήση τους. Έχετε τη δυνατότητα να επιτρέπετε και να διαγράφετε όλα τα cookies ή κάποια από αυτά. Μπορείτε να ρυθμίσετε το πρόγραμμα περιήγησης να μην δέχεται cookies από κανέναν διαδικτυακό τόπο ή να ζητά τη συγκατάθεσή σας πριν δεχτεί ένα cookie στη συσκευή. Αυτές οι ενέργειες σας δίνουν τον πλήρη έλεγχο των cookies που αποθηκεύονται στη συσκευή σας αλλά επιβραδύνουν σημαντικά τη περιήγησής σας στο διαδίκτυο.
      <br />
      <br />
      Μέσω του προγράμματος περιήγησης έχετε επίσης τη δυνατότητα να επιλέξετε να μην λαμβάνετε cookies τρίτων μερών ή cookies συμπεριφορικής διαφήμισης. Σε μερικούς περιηγητές υπάρχει και η δυνατότητα να αποκλείετε συγκεκριμένες εταιρείες οι οποίες δεν επιθυμείτε να αποθηκεύουν cookies στις συσκευές σας.
      <br />
      <br />
      Τα cookies που χρησιμοποιούμε στους διαδικτυακούς μας τόπους έχουν διαφορετικούς όρους προστασίας προσωπικών δεδομένων για τους οποίους μπορείτε να ενημερωθείτε από τη λίστα πιο κάτω. Από την ίδια λίστα μπορείτε να ενημερωθείτε για τους τρόπους που μπορείτε να εξαιρεθείτε από τη χρήση των υπηρεσιών τους.
      <br />
      <br />

      <table className={classes.cookiesPolicyTable}>
        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>

          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Service
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Πολιτική Απορρήτου
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Opt-Out URL
            </div>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Strictly Necessary
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>

          </td>
          <td className={classes.cookiesPolicyTableTd}>

          </td>
          <td className={classes.cookiesPolicyTableTd}>

          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Analytical and Performance
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Google Analytics
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://www.google.com/analytics/terms/us.html`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://tools.google.com/dlpage/gaoptout/`} rel="noreferrer" target="_blank" >Opt-Out</a>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>

            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Alexa
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://www.alexa.com/help/privacy`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://support.alexa.com/hc/en-us/articles/200685410-Opting-Out-of-Alexa-Measurement-Pixel`} rel="noreferrer" target="_blank" >Opt-Out</a>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>

            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              reCAPTCHA
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://policies.google.com/privacy`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>

          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Functionality
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              One Signal
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://onesignal.com/privacy_policy`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://documentation.onesignal.com/docs/opt-out-of-web-push`} rel="noreferrer" target="_blank" >Opt-Out</a>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>

            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Share This
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://www.sharethis.com/privacy/`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://www.sharethis.com/privacy/`} rel="noreferrer" target="_blank" >Opt-Out</a>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>

            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              YouTube (API)
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://policies.google.com/privacy?hl=en`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>

          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>

            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              PlayBuzz
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://www.playbuzz.com/PrivacyPolicy`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`http://www.youronlinechoices.eu/`} rel="noreferrer" target="_blank" >Opt-Out</a>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Ad Targeting and Serving
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Google Ad Manager
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://policies.google.com/technologies/ads?hl=en`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://policies.google.com/technologies/ads`} rel="noreferrer" target="_blank" >Opt-Out</a>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>

            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Taboola
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://www.taboola.com/policies/privacy-policy`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://www.taboola.com/policies/cookie-policy#how-to-manage-cookies`} rel="noreferrer" target="_blank" >Opt-Out</a>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>

            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Facebook pixel
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`https://www.facebook.com/about/privacy/update`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>

          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>

            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              White Walk
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`http://www.whitewalk.eu/privacy-policy`} rel="noreferrer" target="_blank" >Privacy Policy</a>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <a href={`http://www.whitewalk.eu/opt-out`} rel="noreferrer" target="_blank" >Opt-Out</a>
          </td>
        </tr>
      </table>

      <br />
      <br />
      Η εταιρεία Digital Tree Media  σας ενημερώνει ότι οι εταιρείες που παρέχουν online υπηρεσίες μέσω των  cookies ή οποιασδήποτε άλλης τεχνολογίας και δηλώνουν ότι συλλέγουν προσωπικά δεδομένα, δύναται να μοιράζονται αυτά τα δεδομένα με τρίτους. Για αυτό το λόγο θα πρέπει να διαβάσετε προσεκτικά τους λόγους για τους οποίους συλλέγουν, επεξεργάζονται και μοιράζονται τα δεδομένα στις πολιτικές απορρήτου (Privacy Policy) και να εξαιρέσετε τον εαυτό σας από τις χρήσης της ανάλογης υπηρεσίες κάνοντας χρήση των συνδέσμων που θα βρείτε στη παρούσα σελίδα.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>
        Αναλυτικά οι λειτουργίες των  Cookies
      </div>
      <br />
      Η εταιρεία Digital Tree Media σας ενημερώνει ότι οι εταιρείες που παρέχουν online υπηρεσίες μέσω των  cookies ή οποιασδήποτε άλλης τεχνολογίας και δηλώνουν ότι συλλέγουν προσωπικά δεδομένα, δύναται να μοιράζονται αυτά τα δεδομένα με τρίτους. Για αυτό το λόγο θα πρέπει να διαβάσετε προσεκτικά τους λόγους για τους οποίους συλλέγουν, επεξεργάζονται και μοιράζονται τα δεδομένα στις πολιτικές απορρήτου (Privacy Policy) και να εξαιρέσετε τον εαυτό σας από τις χρήσης της ανάλογης υπηρεσίες κάνοντας χρήση των συνδέσμων που θα βρείτε στη παρούσα σελίδα.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>Google Analytics</div>
      <br />
      Αυτός ο ιστότοπος χρησιμοποιεί Google Analytics, μια υπηρεσία ανάλυσης ιστού της Google Inc. («Google»). Το Google Analytics χρησιμοποιεί μια ειδική μορφή «cookie», δηλαδή ένα αρχείο κειμένου το οποίο είναι αποθηκευμένο στον υπολογιστή σας και επιτρέπει την ανάλυση της χρήσης της ιστοσελίδας από εσάς. Οι πληροφορίες σχετικά με τη χρήση αυτού του ιστοτόπου που δημιουργούνται από το cookie, κατά κανόνα διαβιβάζονται σε έναν διακομιστή της Google στις Η.Π.Α. και αποθηκεύονται εκεί. Θα θέλαμε να σας επισημάνουμε ότι το Google Analytics έχει επεκταθεί σε αυτόν τον ιστότοπο για να συμπεριλάβει τον κωδικό «gat._anonymizeIp();» και να εξασφαλίζει την ανώνυμη καταγραφή των διευθύνσεων IP (το λεγόμενο «IP Masking»). Λόγω της ανωνυμίας των IP σε αυτόν τον ιστότοπο, η Google έχει συντομεύσει τη διεύθυνση IP σας εντός της επικράτειας της ΕΕ και των κρατών μελών της Ευρωπαϊκής Οικονομικής Κοινότητας. Μόνο σε εξαιρετικές περιπτώσεις μεταδίδεται η πλήρης διεύθυνση IP σε διακομιστή της Google στις Η.Π.Α. και γίνεται εκεί η συντόμευσή της.
      <br />
      <br />
      Η Google χρησιμοποιεί αυτές τις πληροφορίες για λογαριασμό μας, προκειμένου να αναλύσει την από μέρους σας χρήση αυτού του ιστοτόπου, προκειμένου να  συντάξει αναφορές σχετικά με δραστηριότητες του ιστοτόπου και να παρέχει  στον διαχειριστή του ιστοτόπου πρόσθετες υπηρεσίες που σχετίζονται με τη χρήση αυτού και την χρήση του Διαδικτύου. Η διεύθυνση IP που μεταδίδεται στο Google Analytics από το πρόγραμμα περιήγησης που χρησιμοποιείτε δεν συνδυάζεται με άλλα δεδομένα από το Google. Μπορείτε να αποτρέψετε την αποθήκευση των cookies επιλέγοντας την κατάλληλη ρύθμιση στο λογισμικό του προγράμματος περιήγησης που χρησιμοποιείτε πιο πάνω. Επιπλέον, μπορείτε να απαγορεύσετε στην Google να καταγράφει στοιχεία που σχετίζονται με τη χρήση του ιστοτόπου από εσάς και προέρχονται από το cookie (συμπεριλαμβανομένης της διεύθυνσης IP σας) και να επεξεργάζεται αυτά τα δεδομένα, εφόσον μεταφορτώσετε και εγκαταστήσετε το plug-in του προγράμματος περιήγησης που διατίθεται στον σύνδεσμο
      <a href={`https://tools.google.com/dlpage/gaoptout?hl=en`} rel="noreferrer" target="_blank" > https://tools.google.com/dlpage/gaoptout?hl=en</a>.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>
        Alexa
      </div>
      <br />
      Η υπηρεσία της <a href={`https://www.alexa.com/`} rel="noreferrer" target="_blank" > Alexa</a> που χρησιμοποιούμε παρέχει αναλυτικά στατιστικά στοιχεία που έχουν να κάνουν με τη χρήση των Διαδικτυακών μας Τόπων. Με βάση την <a href={`https://www.alexa.com/help/privacy`} rel="noreferrer" target="_blank" > πολιτική απορρήτου</a> της εταιρείας μεταξύ των στοιχείων που συλλέγονται προκειμένου να παρέχει την υπηρεσία είναι οι σελίδες που επισκέπτεται ο χρήστης, τα στοιχεία του περιηγητή ιστοσελίδων, το λειτουργικό σύστημα και άλλα που περιγράφονται αναλυτικά <a href={`https://www.alexa.com/help/privacy`} rel="noreferrer" target="_blank" > εδώ</a>.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>reCAPTCHA</div>
      <br />
      Το reCAPTCHA είναι ένα σύστημα το οποίο αναπτύχθηκε αρχικά από τους Luis von Ahn, Ben Maurer, Colin McMillen, Ντέιβιντ Αβραάμ και Manuel Blum στο Πανεπιστήμιο Carnegie Mellon,  και εξαγοράστηκε από την Google το Σεπτέμβριο του 2009. Είναι ένα text based CAPTCHA αλλά με μια σημαντική  καινοτομία. Οι λέξεις που εμφανίζονται για την λύση του CAPTCHA είναι λέξεις από βιβλία τα οποία είναι στο στάδιο της ψηφιοποίησης. Το reCAPTCHA ζητά από τους χρήστες να εισάγουν λέξεις που εμφανίζονται σε παραμορφωμένες εικόνες κειμένου στην οθόνη. Με την υποβολή των δύο λέξεων που απαιτούνται από το reCAPTCHA  προστατεύονται και οι ιστοσελίδες από bots,που  προσπαθούν να έχουν πρόσβαση σε υπηρεσίες περιορισμένης πρόσβασης  και βοηθά στην ψηφιοποιήση βιβλίων. <a href={`https://el.m.wikipedia.org/wiki/ReCAPTCHA`} rel="noreferrer" target="_blank" > Περισσότερα εδώ</a>.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>One Signal</div>
      <br />
      Η εταιρεία <a href={`https://onesignal.com/`} rel="noreferrer" target="_blank" > One Signal</a> παρέχει τη δυνατότητα στους χρήστες να λαμβάνουν ειδοποιήσεις (web push notifications) σχετικές με το περιεχόμενο που δημοσιεύεται στους Διαδικτυακούς Τόπους της εταιρείας Digital Tree Media. Στην <a href={`https://onesignal.com/privacy_policy`} rel="noreferrer" target="_blank" > πολιτική απορρήτου </a> της εταιρείας αναφέρονται με λεπτομέρειες τα δεδομένα που συλλέγονται και ο τρόπος που χρησιμοποιούνται. Επίσης, η εταιρεία αναφέρει ότι τα δεδομένα που συλλέγονται μπορεί να τα μοιραστούν με τρίτους. Όσοι χρήστες δεν επιθυμούν να χρησιμοποιήσουν την υπηρεσία μπορούν να εξαιρεθούν χρησιμοποιώντας τις οδηγίες που παρέχονται <a href={`https://documentation.onesignal.com/docs/opt-out-of-web-push`} rel="noreferrer" target="_blank" >  εδώ</a>.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>
        Λειτουργία προτεινόμενου περιεχομένου Taboola
      </div>
      <br />
      H εφαρμογή προτεινόμενου περιεχομένου Taboola προσφέρει μια εξατομικευμένη πλατφόρμα προτεινόμενου περιεχομένου. Με τη χρήση της υπηρεσίας ο χρήστης περιηγείται ευκολότερα από το ένα θέμα στο άλλο καθώς τα θέματα που εμφανίζονται είναι σχετικά με τα ενδιαφέροντά του. Η ίδια υπηρεσία έχει τη δυνατότητα να παρουσιάζει διαφημιστικά άρθρα από άλλες ιστοσελίδες. Για να πετύχει αυτό το αποτέλεσμα είναι απαραίτητη η συλλογή μιας σειράς δεδομένων που αφορούν τον τρόπο που ο χρήστης περιηγείται στο διαδίκτυο και τις οποίες εξηγεί και αναλύει η εταιρεία στην  <a href={`https://www.engageya.com/privacy`} rel="noreferrer" target="_blank" > πολιτική απορρήτου</a>.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>Share This</div>
      <br />
      Η υπηρεσία Share This παρέχει μία ολοκληρωμένη πλατφόρμα κοινοποίησης περιεχομένου στα κοινωνικά δίκτυα. Μέσω αυτής της υπηρεσίας ο χρήστης μπορεί εύκολα να μοιράζεται περιεχόμενο στο Facebook, Twitter, Google+ και άλλες πλατφόρμες ή να αποστέλλει το περιεχόμενο σε email ή εκτυπωτή. Η υπηρεσία Share This παράλληλα με αυτή την υπηρεσία χρησιμοποιεί τεχνολογία που επιτρέπει τη συλλογή δεδομένων σχετικά με τη περιήγηση και την κοινή χρήση. Αυτό επιτρέπει σε εκδότες, διαφημιζόμενους συνεργάτες επεξεργασίας δεδομένων να διευκολύνουν την παροχή σχετικής και στοχευμένης διαφήμισης βάσει των μοντέλων και των αναλύσεων των δεδομένων που δημιουργούνται από την χρήση της υπηρεσίας. Αναλυτικά η εταιρεία παρέχει όλες τις πληροφορίες για τον τρόπο χρήσης και επεξεργασίας των δεδομένων που συλλέγει στην <a href={`https://www.sharethis.com/privacy/`} rel="noreferrer" target="_blank" > πολιτική απορρήτου</a>. Στην ίδια σελίδα θα βρείτε και τη δυνατότητα να εξαιρεθείτε εάν επιθυμείτε από τις υπηρεσίες τις εταιρείας.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>
        YouTube API
      </div>
      <br />
      H Διασύνδεση Προγραμματισμού Εφαρμογών  (API - Application Programming Interface), είναι η διεπαφή των προγραμματιστικών διαδικασιών που παρέχει μια εφαρμογή προκειμένου να επιτρέπει να γίνονται προς αυτήν αιτήσεις από άλλα προγράμματα ή/και ανταλλαγή δεδομένων. Είναι δηλαδή, ένα ενδιάμεσο λογισμικό που επιτρέπει την επικοινωνία μεταξύ δύο εφαρμογών.
      <br />
      <br />
      Η Διασύνδεση Προγραμματισμού Εφαρμογών YouTube (YouTube API) επιτρέπει στους προγραμματιστές να έχουν πρόσβαση σε στατιστικά των βίντεο και σε δεδομένα καναλιών YouTube μέσω REST και XML-RPC. Η Google περιγράφει τα YouTube API Resources ως  εργαλεία που επιτρέπουν να μεταφερθεί η εμπειρία του YouTube σε μια ιστοσελίδα ή εφαρμογή.
      <br />
      <br />
      Διαβάστε περισσότερα για τους Όρους Χρήσης της υπηρεσίας: <a href={`https://www.youtube.com/t/terms`} rel="noreferrer" target="_blank" > You Tube Terms of Service (ToS)</a>
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>
        PlayBuzz
      </div>
      <br />
      To Playbuzz είναι μία ψηφιακή πλατφόρμα η οποία αυξάνει τη διαδραστικότητα μεταξύ του χρήστη και του περιεχομένου. Χρησιμοποιώντας το Playbuzz δημιουργούμε quiz, ψηφοφορίες, λίστες, flip cards και άλλες λειτουργίες οι οποίες βελτιώνουν τον τρόπο παρουσίασης του περιεχόμενου και δίνουν τη δυνατότητα διάδρασης του χρήστη με την ιστοσελίδα. Η πλατφόρμα Playbuzz χρησιμοποιεί διάφορες τεχνολογίες ανίχνευσης μεταξύ άλλων web beacons, cookies, pixel tags, scripts, tags για να συλλέγει προσωπικά και μη προσωπικά δεδομένα του χρήστη, τα οποία μοιράζεται με τρίτους με στόχο τη μέτρηση, ανάλυση και επεξεργασία των δεδομένων για βελτίωση των υπηρεσιών της και εμφάνιση σχετικών διαφημίσεων. Τα δεδομένα και ο ακριβής τρόπος συλλογής και χρήσης περιγράφονται στην  <a href={`https://www.playbuzz.com/PrivacyPolicy`} rel="noreferrer" target="_blank" > Πολιτική απορρήτου της πλατφόρμας</a>.
      <br />
      <br />
      Εάν επιθυμείτε να απενεργοποιήσετε τις υπηρεσίες ανίχνευσης που χρησιμοποιεί τόσο το Playbuzz όσο και άλλες τρίτες πλατφόρμες μπορείτε να επισκεφθείτε το <a href={`http://www.networkadvertising.org/choices/`} rel="noreferrer" target="_blank" > Network Advertising Initiative</a> ή το <a href={`http://www.aboutads.info/choices/`} rel="noreferrer" target="_blank" > DAA opt-out program</a> ή το <a href={`http://www.youronlinechoices.eu/`} rel="noreferrer" target="_blank" > EDAA consumer choice page</a>. Οι υπηρεσίες αυτές απενεργοποιούν την ανίχνευση σε επίπεδο φυλλομετρητή (browser) κάτι που σημαίνει ότι πρέπει να επαναλάβετε τη διαδικασία σε κάθε συσκευή και φυλλομετρητή (browser) που χρησιμοποιείτε.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>
        Google Ad Manager
      </div>
      <br />
      Χρησιμοποιούμε τη λειτουργία και τα cookies του <a href={`https://admanager.google.com/home/`} rel="noreferrer" target="_blank" > Google Ad Manager</a> στους ιστοτόπους μας προκειμένου να αξιολογήσουμε τη χρήση του ιστοτόπου και να είμαστε σε θέση, τόσο εμείς όσο και η Google καθώς επίσης και άλλοι διαφημιζόμενοι που συνεργάζονται με τoν Google Ad Manager, να σας παρουσιάσουμε διαφημίσεις που ενδιαφέρουν εσάς ως χρήστη. Με τη βοήθεια τέτοιων cookies, εκχωρείται στο πρόγραμμα περιήγησής σας ένας ανώνυμος αναγνωριστικός αριθμός και συλλέγονται πληροφορίες σχετικά με τις διαφημίσεις που εμφανίζονται στο πρόγραμμα περιήγησής σας και την πρόσβαση σε αυτές. Οι πληροφορίες που συλλέγονται από το cookie σχετικά με τη χρήση των ιστοτόπων από εσάς συνήθως μεταδίδονται σε έναν διακομιστή της Google στις ΗΠΑ και αποθηκεύονται εκεί. Με βάση τις πληροφορίες που συλλέγονται, δίδονται στο πρόγραμμα περιήγησής σας κατηγορίες ανάλογα με τα ενδιαφέροντα. Αυτές οι κατηγορίες χρησιμοποιούνται για την εμφάνιση διαφημίσεων που σχετίζονται με τα ενδιαφέροντα του χρήστη.
      <br />
      <br />
      Εκτός από την αλλαγή των ρυθμίσεων του προγράμματος περιήγησης, μπορείτε να απενεργοποιήσετε μόνιμα το Ad Manager cookie με τη βοήθεια ενός plug-in του προγράμματος περιήγησης. Με το plug-in, διατηρούνται οι ρυθμίσεις απενεργοποίησης για αυτό το πρόγραμμα περιήγησης, ακόμη και αν διαγράψετε όλα τα cookies. Μπορείτε να αποκτήσετε το plug-in του προγράμματος περιήγησης για μόνιμη απενεργοποίηση εδώ .
      <br />
      <br />
      Χρησιμοποιώντας τον ιστότοπό μας, συμφωνείτε με την εισαγωγή του cookie του Ad Manager στον υπολογιστή σας και συνεπώς με τη χρήση των δεδομένων σας που συλλέγονται, αποθηκεύονται και χρησιμοποιούνται κατά τον τρόπο που περιγράφεται παραπάνω για τον παραπάνω σκοπό. Επιπλέον, συμφωνείτε ότι τα δεδομένα σας θα αποθηκευτούν σε cookies μετά το τέλος της αναζήτησης και ότι είναι δυνατή η πρόσβαση σε αυτά όταν, για παράδειγμα, επισκεφθείτε ξανά τους ιστοτόπους. Μπορείτε να ανακαλέσετε αυτή τη συγκατάθεση ανά πάσα στιγμή με ισχύ για το μέλλον μέσω της διαγραφής του DoubleClick cookie και της μόνιμης απενεργοποίησής του.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>
        White Walk
      </div>
      <br />
      Η White Walk είναι ένα τεχνολογικό δίκτυο που επικεντρώνεται σε καινοτόμες λύσεις διαφήμισης. Η υπηρεσία δεν συλλέγει προσωπικά δεδομένα με βάση την <a href={`http://www.whitewalk.eu/privacy-policy`} rel="noreferrer" target="_blank" > πολιτική απορρήτου</a> της. Οι χρήστες που επιθυμούν όμως να εξαιρεθούν από την χρήση της υπηρεσίας μπορούν να το κάνουν πατώντας <a href={`http://www.whitewalk.eu/opt-out`} rel="noreferrer" target="_blank" > εδώ.</a>
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>
        Facebook Pixel
      </div>
      <br />
      Το Facebook pixel είναι ένα εργαλείο που μας βοηθά να πετύχουμε τα πιο κάτω:
      <br />
      <br />

      <div className={classes.padded}>
        <ul>
          <li>
            Μέτρηση της αποτελεσματικότητας των διαφημιστικών ενεργειών μας, κατανοώντας τις ενέργειες των χρηστών πάνω στη χρήση του περιεχομένου και των υπηρεσιών των ιστοσελίδων του Sigmalive
          </li>

          <li>Επιβεβαιώνουμε ότι οι διαφημίσεις μας προβάλλονται στο σωστό κοινό</li>

          <li>
            Ομαδοποιούμε κοινά χρηστών για διαφημιστικούς σκοπούς
          </li>

          <li>
            Βοηθάμε τους χρήστες να δουν περιεχόμενο που ανταποκρίνεται περισσότερα στα ενδιαφέροντα τους (personalization)
          </li>
        </ul>
      </div>
      <br />
      <br />
      Αν δεν έχετε λογαριασμό στο Facebook, μπορείτε να ακολουθήσετε τις πιο κάτω οδηγίες του Facebook.
      <br />
      <br />
      Μπορείτε να εξαιρεθείτε από τις online διαφημιστικές ενέργειες συγκεκριμένου ενδιαφέροντος από το Facebook ή άλλες εταιρείες μέσω του <a href={`http://www.youronlinechoices.eu/`} rel="noreferrer" target="_blank" > European Interactive Digital Advertising Alliance</a> για την Ευρώπη, του <a href={`http://www.aboutads.info/choices/`} rel="noreferrer" target="_blank" > Digital Advertising Alliance</a> για τις Η.Π.Α., το <a href={`http://www.aboutads.info/choices/`} rel="noreferrer" target="_blank" > Digital Advertising Alliance of Canada</a> για τον Καναδά ή κάνοντας χρήση των ρυθμίσεων της κινητής σας συσκευής. Λάβετε υπόψιν ότι τυχόν ad blockers ή άλλα εργαλεία που απαγορεύουν τη χρήση των cookies μπορεί να συγκρούονται με τις πιο πάνω λειτουργίες.
      <br />
      <br />
      Για περισσότερες πληροφορίες, επισκεφθείτε την <a href={`https://www.facebook.com/policies/cookies/`} rel="noreferrer" target="_blank" > Πολιτική Απορρήτου του Facebook</a>
      <br />
      <br />
      Εάν έχετε λογαριασμό στο Facebook τότε μπορείτε να ελέγξετε τις ρυθμίσεις των διαφημίσεων από το <a href={`https://www.facebook.com/ads/settings/`} rel="noreferrer" target="_blank" > Facebook Ads Settings</a>
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>Web Notifications</div>
      <br />
      Τα Web Notifications είναι ειδοποιήσεις που μπορούν να σταλούν στους χρήστες μέσω των περιηγητών. Έχουν τη μορφή των έκτακτων ειδοποιήσεων που εμφανίζονται στη πάνω ή κάτω δεξιά γωνία της οθόνης του υπολογιστή ανάλογα με το λειτουργικό σύστημα. Τα Web Push Notifications εμφανίζονται στην οθόνη του χρήση οποιαδήποτε ώρα έχουν ανοιχτό τον περιηγητή και ανεξάρτητα αν είναι ή όχι στις ιστοσελίδες της εταιρείας Digital Tree Media
      <br />
      <br />
      Για να ενεργοποιήσετε ή να απενεργοποιήσετε τα Web Notifications ακολουθήστε τις <a href={`https://support.google.com/chrome/answer/3220216?co=GENIE.Platform%3DDesktop&hl=en`} rel="noreferrer" target="_blank" > οδηγίες που θα βρείτε εδώ</a>. Όταν ενεργοποιείται η υπηρεσία τοποθετεί στον υπολογιστή ένα cookie το οποίο ενημερώνει αν ο χρήστης έχει ή όχι αποδεχθεί την υπηρεσία των ειδοποιήσεων. Πέραν αυτής της υπηρεσίας τα cookies δεν συλλέγουν κανένα προσωπικό δεδομένο των χρηστών.
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>ΧΡΗΣΗ PLUGINS/ΕΝΣΩΜΑΤΩΣΗ ΚΟΙΝΩΝΙΚΩΝ ΔΙΚΤΥΩΝ</div>
      <br />
      Η εταιρεία Digital Tree Media χρησιμοποιεί στις ιστοσελίδες της plugins κοινωνικών δικτύων Τα plugins συνήθως αναγνωρίζονται από το λογότυπο του κάθε κοινωνικού δικτύου..
      <br />
      <br />
      Το κουμπί “κοινοποίηση” ή “κοινοποίηση με φίλους” του παρόχου Facebook διαχειρίζεται η Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. Η εταιρεία Facebook Ireland Limited, Hanover Reach, 5-7 Hanover Quay, Dublin 2, Ireland, είναι υπεύθυνη για τους ιστοτόπους που λειτουργούν σε χώρες της Ευρωπαικής Ένωσης.
      <br />
      <br />
      Το plugin από το Google+ το διαχειρίζεται η εταιρεία Google Inc., Amphitheatre Parkway, Mountain View, CA 94043, USA. Το plugin του Twitter η εταιρεία Twitter, Inc., 1355 Market St, Suite 900, San Francisco, CA 94103 και το plugin του Pinterest Πάροχος: Pinterest Inc., 808 Brannan Street San Francisco, CA 94103, USA.
      <br />
      <br />
      Όταν ο χρήστης επιλέγει να πατήσει το ανάλογο κουμπί τότε δημιουργείται από το πρόγραμμα περιήγησης μία σύνδεση με τους διακομιστές του αντίστοιχου κοινωνικού δικτύου. Κάνοντας μία από τις πιο κάτω επιλογές “Δημοσίευση”, “Κοινοποίηση” ή “Κοινοποίηση με φίλους” συμφωνείτε στη δημιουργία αυτής της σύνδεσης του προγράμματος περιήγησής σας με τον διακομιστή του αντίστοιχου κοινωνικού δικτύου προκειμένου να μεταδώσει δεδομένα χρήσης στον αντίστοιχο διαχειριστή του κοινωνικού δικτύου.
      <br />
      <br />
      Η εταιρεία Digital Tree Media δεν επηρεάζει ή ελέγχει με οποιονδήποτε τρόπο τα στοιχεία που διαβιβάζονται στη συνέχεια ή που συγκεντρώνονται από τα αντίστοιχα κοινωνικά δίκτυα. Περισσότερες πληροφορίες σχετικά τη συλλογή και επεξεργασία ή χρήση των δεδομένα από τα αντίστοιχα κοινωνικά δίκτυα, όπως επίσης, πληροφορίες σχετικά με τα δικαιώματα και τις διαθέσιμες ρυθμίσεις για την προστασία της ιδιωτικής σας σφαίρας μπορείτε να πάρετε από την αντίστοιχη πολιτική προστασίας προσωπικών δεδομένων του αντίστοιχου κοινωνικού δικτύου.
      <br />
      <br />
      <table className={classes.cookiesPolicyTable}>
        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Κοινωνικό Δίκτυο
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Πολιτική Προστασίας Προσωπικών Δεδομένων
            </div>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Facebook
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              <a href={`http://www.facebook.com/about/privacy/`} rel="noreferrer" target="_blank" > http://www.facebook.com/about/privacy/ </a>
            </div>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Twitter
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              <a href={`http://twitter.com/privacy`} rel="noreferrer" target="_blank" > http://twitter.com/privacy</a>
            </div>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Google+
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              <a href={`http://www.google.de/intl/de/policies/privacy/`} rel="noreferrer" target="_blank" > http://www.google.de/intl/de/policies/privacy/</a>
            </div>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Pinterest
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              <a href={`http://about.pinterest.com/privacy`} rel="noreferrer" target="_blank" > http://about.pinterest.com/privacy</a>
            </div>
          </td>
        </tr>

        <tr className={classes.cookiesPolicyTableTr}>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              Tik Tok
            </div>
          </td>
          <td className={classes.cookiesPolicyTableTd}>
            <div className={classes.cookiesPolicyTableHeader}>
              <a href={`https://www.tiktok.com/legal/privacy-policy-eea?lang=el`} rel="noreferrer" target="_blank" > https://www.tiktok.com/legal/privacy-policy-eea?lang=el</a>
            </div>
          </td>
        </tr>
      </table>
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}>ΟΔΗΓΟΣ ΔΙΑΓΡΑΦΗΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</div>
      <br />
      E-mail at <a className={classes.ContactUs_description_email_button} href='mailto:dpo@digitaltree.com.cy' target='_blank' rel='noreferrer'>dpo@digitaltree.com.cy</a> to receive the instructions
      <br />
      <br />
      <ol>
        <li>
          COOKIES
        </li>
        <ol type="A">
          <li>
            INTERNET BROWSER on windows personal computer
          </li>
          <ol type="i">
            <li>Google Chrome <a href={`https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en`} rel="noreferrer" target="_blank" > English</a> - <a href={`https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=el`} rel="noreferrer" target="_blank" > Ελληνικά</a></li>
            <li>Mozilla Firefox <a href={`https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored`} rel="noreferrer" target="_blank" > English</a> - <a href={`https://support.mozilla.org/el/kb/diagrafh-cookies-gia-afairesh-plhroforiwn`} rel="noreferrer" target="_blank" > Ελληνικά</a></li>
            <li>Safari <a href={`https://support.apple.com/kb/ph21411?locale=en_GB`} rel="noreferrer" target="_blank" > English</a> - <a href={`https://support.apple.com/kb/ph21411?locale=el_GR`} rel="noreferrer" target="_blank" > Ελληνικά</a></li>
            <li>Microsoft edge <a href={`https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy`} rel="noreferrer" target="_blank" > English</a> - <a href={`https://privacy.microsoft.com/el-GR/windows-10-microsoft-edge-and-privacy`} rel="noreferrer" target="_blank" > Ελληνικά</a></li>
            <li>Opera <a href={`https://www.opera.com/help/tutorials/security/privacy/`} rel="noreferrer" target="_blank" > English</a> - Ελληνικά</li>
          </ol>
        </ol>

      </ol>
      <br />
      <br />
      <div className={`${classes.cookiesPolicyTitle} font-400`}> Ενημέρωση, επικαιροποίηση και τροποποίηση </div>
      <br />
      Η Εταιρεία διατηρεί το δικαίωμα να τροποποιεί/ επικαιροποιεί επιμέρους τμήματα της παρούσας Πολιτικής, χωρίς υποχρέωση σχετικής προηγούμενης ενημέρωσης σας. Σας συμβουλεύουμε να διαβάζετε την Πολιτική προστασίας δεδομένων προσωπικού χαρακτήρα, προτού χρησιμοποιήσετε τον ιστότοπό μας, ώστε να ενημερώνεστε για την τρέχουσα έκδοση της Πολιτικής, σε περίπτωση που έχουν γίνει τυχόν τροποποιήσεις ή ενημερώσεις.
      <br />
      <br />
      Με την υποβολή των δεδομένων σας, επιβεβαιώνετε ότι έχετε διαβάσει και συμφωνήσει με την Πολιτική Προστασίας Προσωπικών Δεδομένων της DIgital Tree Media
    </div>
  );
};

export default CookiesPolicy;
