import { NavLink } from 'react-router-dom';
import { getImageByKey } from '../../scripts/getImageByKey';
import { useTranslation } from 'react-i18next';
import * as classes from './NavBar.module.css';
import { CgMenu, CgClose } from 'react-icons/cg';
// import { useState } from 'react';
import { useMyContext } from '../../contexts/StateHolder';
import MobileBottomNav from './MobileBottomNav';
import settings from '../../configs/config_settings.json'
import facebook from '../../images/facebook.png'
import insta from '../../images/instra.png'
import profile from '../../images/profile.png'

const NavBar = ({
  links,

  styles,
  linksMobileLogin,
  linksMobileSearch,
  linksMobileLanguageSelect,
  linksMobileProfile,
}) => {
  const { t } = useTranslation();

  const {
    isMenu,
    setisMenu,
    closeHamMenu,
    isResponsiveclose,
    setResponsiveclose,
    user,
  } = useMyContext();
  // for main navigation mobile menu
  const toggleClass = () => {
    console.log(`toggle`, isMenu, isResponsiveclose);
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ['main-menu menu-right menuq1'];
  if (isMenu) {
    boxClass.push('menuq2');
  } else {
    boxClass.push('');
  }

  const mobileLinks = () => {
    return (
      <div className={classes.dropdownContent} onClick={() => {
        setisMenu(!isMenu)
        setResponsiveclose(!isResponsiveclose)
      }}>
          <a href='/profile'><div className={classes.MenuItem}>Profile<img src={profile} alt="" /></div></a>
          <div style={{borderTop: "1px solid rgba(255, 255, 255, 0.438)"}} className={classes.MenuItem}>
            <a href='https://www.facebook.com/mytvbroadcasting/' style={{border: "none"}}>Social Media <img src={facebook} alt="" /></a>
          </div>
        <div style={{borderTop: "1px solid rgba(255, 255, 255, 0.438)"}}>
          <a href='https://www.mytvbroadcasting.my/hubungi_kami/' style={{border: "none"}}>Contact Us</a>
        </div>
        <NavLink to={settings.routes.home} exact><div>Privacy</div></NavLink>
      </div>
    )
  }
  // for dropdown navigation mobile menu
  // const [isMenuSubMenu, setMenuSubMenu] = useState(false);

  // const toggleSubmenu = () => {
  //   setMenuSubMenu(isMenuSubMenu === false ? true : false);
  // };

  // console.log(toggleSubmenu);

  // let boxClassSubMenu = ['sub__menus'];
  // if (isMenuSubMenu) {
  //   boxClassSubMenu.push('sub__menus__Active');
  // } else {
  //   boxClassSubMenu.push('');
  // }

  return (
    <>
      <div className={classes.navColorBar}>
        <div className={classes.navColorBar1}></div>
        <div className={classes.navColorBar2}></div>
      </div>
      <div className={`${isResponsiveclose && classes.navBar1} ${classes.navBar}`} style={styles?.navBar}>
        {
          // site logo for big screen
          <NavLink to='/' exact activeClassName='no-class'>
            <img
              className='navBarSiteLogo'
              src={getImageByKey('siteLogo')}
              title={t('navBar.backToHome')}
              alt='SiteName'
              style={styles?.navBar}
              onClick={closeHamMenu}
            ></img>
          </NavLink>
        }

        <nav className={`${classes.navBarRow} main-nav `}>
          {/* navbar for the big screen*/}
        

          <div className={'navBarSiteLogoMobile'}>
            <NavLink to='/' exact activeClassName='no-class'>
              <img
                src={getImageByKey('siteLogo')}
                title={t('navBar.backToHome')}
                alt='SiteName'
                style={styles?.navBar}
                onClick={closeHamMenu}
                className='navBarSiteLogoMobile-img'
              ></img>
            </NavLink>
          </div>

          <div>
         <ul className={` ${classes.navBarRow_primary} main-menu menu-right menuq1`}>
            {links}
          </ul>

          {isMenu && 
          <ul className={` ${classes.navBarRow_primary} ${boxClass.join(' ')}`}> {/* // hambuggermenu and close button on small screen */}
            {mobileLinks()}
            {/* {user.loggedIn && (
              <div className='navBarSiteLogoMobile'>{linksMobileProfile}</div>
            )}
            <div className='navBarSiteLogoMobile languageSelectMobile'>
              {linksMobileLanguageSelect}
            </div> */}
          </ul>
          }
         </div>
         
          <div className={'navBarSiteLogoMobile'}>{linksMobileLogin}</div>
          <div className='action'>
            {isResponsiveclose === true ? (
              <>
                <span
                  className='menubar__button '
                  
                  onClick={toggleClass}
                >
                  {' '}
                  <CgClose />
                </span>
              </>
            ) : (
              <>
                <span
                  className='menubar__button menubar__button1'
                  onClick={toggleClass}
                >
                  {' '}
                  <CgMenu />
                </span>
              </>
            )}
            {/* // search will only appear when all links are not opened in navabar */}
            {!isResponsiveclose && (
              <div className={'navBarSiteLogoMobile'}>{linksMobileSearch}</div>
            )}
          </div>

        </nav>
      </div>
      <div className='MobileNav'>
      <MobileBottomNav/>
      </div>
    
    </>
  );
};

export default NavBar;
