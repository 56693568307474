import React, {useEffect} from "react";
import ReactPlayer from 'react-player'
import { useMyContext } from "../../contexts/StateHolder";
import * as classes from "./RadiantPlayer.module.css";

const ReactVideoPlayer = (props) => {
    const {chosenItem} = useMyContext();
    const query = new URLSearchParams(window.location.search);
    
    const channelName = query.get('channelName').replace(' ','');
    const duration = query.get('duration');
    const startTime = query.get('startTime')
    const title = query.get('title');
    let UTC = parseInt(new Date(startTime).getTime().toString().substring(0,10));

    useEffect(() => {
        const src = {
            hls: `https://vod.mana2.my/${channelName}/index-${UTC}-${duration}.m3u8`
          };
          const settings = {
            licenseKey: 'Kl8lc3k9b3Y4MDJ5ZWk/cm9tNWRhc2lzMzBkYjBBJV8q',
            src: src,
            width: 900,
            height: '100vh',
            hlsJSStopDownloadWhilePaused: true,
          };
          const elementID = 'rmp';
          const rmp = new window.RadiantMP(elementID);
          rmp.init(settings);
    },[])

    // if(channelName === 'AwesomeTV') UTC = parseInt(new Date(startTime).getTime().toString().substring(0,10));
    // if(channelName === 'TVS') UTC = parseInt(new Date(startTime).getTime().toString().substring(0,10));

    return (
      <div className={`${classes.react_Player} replyPlayer`}>

        <div id="rmp"></div>
      </div>
        // <>
        //     <div className={classes.PlayerReactContainer} >
        //     <ReactPlayer 
        //         url={`https://live.mytvott.info/${channelName}/index-${UTC}-${duration}.m3u8`} 
        //         controls={true}
        //         width='100%'
        //         height='100%'
        //     />
        //     </div>
        // </>
    )
}

export default ReactVideoPlayer

// https://live.mytvott.info/${channelName}/index-${UTC}-${duration}.m3u8