import { useMyContext } from "../../contexts/StateHolder";
import RenderItem from "../ViewAssets/RenderItems/RenderItem";
import CategoryTitle from "../ViewAssets/CategoryTitles/CategoryTitle";
import CategoryTitle2 from "../ViewAssets/CategoryTitles/CategoryTitle2/CategoryTitle2";
import { useEffect, useState } from "react";
import {
  getSeries,
  getEpisodes,
  getSeriesGroup,
} from "../../scripts/dataHandlers";
import { createToken, createGroupItemId } from "../../scripts/utils";
import Carousel from "react-multi-carousel";
import * as classes from "./EpisodesWithSerieTitles.module.css";
import axios from "axios";
//import { FiCloudLightning } from 'react-icons/fi';
import useWindowDimensions from "../WindowDimension";
import { useParams } from "react-router-dom";

// List of usable category components
const components = {
  CategoryTitle,
  CategoryTitle2,
};
/*
Fetch: getAssets with props.groupItemId
Renders: props categories with title and content in slick slider
*/
const AllShows = (props) => {
  const windowDimension = useWindowDimensions();
  // Destructure props.settings
  const {
    id,
    routes,
    slickSettings,
    assetProperty,
    categoryTitleComponent,
    itemImageComponent,
    itemTitleComponent,
    maintainImageAspectRatio,
    groupItemId,
    showAssetsWithGroupId,
  } = props.settings;

  // console.log(routes, 'routes');
  const { showName, showId } = useParams();
  const [items, setItems] = useState([]);

  //const [orderArray, setOrderArray] = useState([]);

  // Bring stateholders from context
  const { organizationId, key, language, user, setChosenItem } = useMyContext(); // + allCategoryItems, setAllCategoryItems when using context

  // UseEffect that will check mode and context data by id, if component has already fetched items once
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getDataFromAPI = async () => {
      try {
        // If component has groupItemId set, get chosen series
        const res =
          groupItemId && groupItemId !== ""
            ? await getSeriesGroup(
                organizationId,
                groupItemId,
                language,
                user,
                source
              )
            : // If not, get them all
              await getSeries(
                organizationId,
                key,
                language,
                user,
                assetProperty,
                source
              );

        const newOrderArray = res;
        // console.log(`series`, res);

        await Promise.all(
          res.map(async (serie) => {
            try {
              const groupIdString = createGroupItemId(serie.groupItems);

              // Call createToken function to create new token from given data
              let serieToken = createToken(organizationId, showId, key);

              // Get episodes for series
              const response = await getEpisodes(
                organizationId,
                showId,
                serieToken,
                language,
                props.assetProperty,
                user,
                source
              );
              setItems(response);
            //   console.log(`episode`, response);
            } catch (error) {
              console.log("error" + error);
            }
          })
        );
      } catch (err) {
        console.log(err);
      }
    };

    if (organizationId && key && language && slickSettings) {
      getDataFromAPI();
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, organizationId, key]);

  const clichHandler = (item, i) => {
    setChosenItem(item);
    props.setEpisodeNumber(i + 1)
  };
  
  const renderSeasons = () => {
    const TitleComponent = components[categoryTitleComponent];

    return (
      <>
      <TitleComponent
          id={id}
          title={showName}
          routes={routes}
          // item={serie}
          showSerieViewAll={false}
          isSerie={true}
        />
      <div className={`${classes.categoriesContainer} ${classes.tvShowAll}`}>
        {items.length > 0 &&
          items.map((item, i) => {
            return (
              item.groupItemIds.includes(showAssetsWithGroupId || "") && (
                <div key={i} onClick={() => clichHandler(item, i)}>
                  <RenderItem
                    key={`${id} ${i}`}
                    index={i}
                    item={item}
                    pushRoute={`/${routes.serieRoute}/${organizationId}/${item.id}`}
                    playOnClick={true}
                    itemImageComponent={itemImageComponent}
                    itemTitleComponent={itemTitleComponent}
                    imageType={"thumbnail"}
                    showCategoryName={false}
                    showPlayIcon={true}
                    showDuration={props.showDuration}
                    showReleaseYear={props.showReleaseYear}
                    maintainImageAspectRatio={maintainImageAspectRatio}
                    isSerie={true}
                    showPublishedDate={props.showPublishedDate}
                    showTitle={true}
                    hideDescription={true}
                  />
                </div>
              )
            );
          })}
      </div>
      </>
    );
  };
  return items.length > 0 ? renderSeasons() : null;
};

export default AllShows;
