import React from "react";
import Carousel from "react-multi-carousel";
import * as classes from "./Banner.module.css";
import { useMyContext } from "../../../contexts/StateHolder";
import settings from "../../../configs/config_settings.json";
import { useHistory } from "react-router-dom";
import { convertDuration } from "../../../scripts/utils";

function HeroBanner(props) {
    const { bannerItems, slickSettings } = props;
    const { setChosenItem, organizationId } = useMyContext();
    const history = useHistory();

    const clickHander = (item) => {
        setChosenItem(item);
        if (item.pageUrl) {
            // open in new page

            window.open(item.pageUrl);
        } else if (item.isSerie) {
            history.push(`/${settings.routes.playSerie}/${organizationId}/${item.id}`);
        } else if (!item.isSerie) {
            history.push(`/${settings.routes.playVideo}/${organizationId}/${item.id}`);
        }
    };

    // console.log(bannerItems)
    return (
        <div className={classes.HeroCarousel}>
            <div>
                <Carousel {...slickSettings} swipeable={true} draggable={true} showDots={true} autoPlay={true} autoPlaySpeed={3000}>
                    {bannerItems.map((el, index) => {
                        // let textDescription = el.isSerie ? `Updated to 15/ Total ${el.folders.length} Episodes` : `${duration()}`;
                        console.log("el", el);
                        return (
                            <div
                                key={index}
                                className={classes.BannerImg}
                                onClick={() => {
                                    el?.pageUrl !== "" && clickHander(el);
                                }}
                                style={{
                                    cursor: el?.pageUrl !== "" && "pointer",
                                }}
                            >
                                <img src={el.bannerImageSmall} alt="heroImage" width="100%" />
                                {el?.pageUrl === "" && (
                                    <>
                                        <svg
                                            style={{ cursor: "pointer" }}
                                            onClick={() => clickHander(el)}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="100"
                                            height="100"
                                            preserveAspectRatio="xMidYMid meet"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="#faca12"
                                                d="m9.5 16.5l7-4.5l-7-4.5ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
                                            />
                                        </svg>
                                        <div className={classes.HeroContents}>
                                            <h3>{el.name.substring(0, 30)}</h3>
                                            {el?.folders?.length > 0 && (
                                                <div className={classes.HeroText}>
                                                    {el?.folders.map((el1, i) => (
                                                        <h6 key={i.toString()}>{el1.name}</h6>
                                                    ))}
                                                </div>
                                            )}
                                            {el?.duration && <h4> {convertDuration(el.duration)} </h4>}
                                            {el.ingress && <p>{el.ingress}</p>}
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </div>
    );
}

export default HeroBanner;
