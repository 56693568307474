import React from 'react';
import { useEffect, useState } from 'react';
import {
  getLatestAssets,
  getTrendingAssets,
  getAssets,
} from '../../scripts/dataHandlers';
import { createToken } from '../../scripts/utils';
import { useMyContext } from '../../contexts/StateHolder';
import RenderItem from '../ViewAssets/RenderItems/RenderItem';
import * as classes from './ListAllCategories.module.css';
// import Carousel from 'react-multi-carousel';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form'
import CategoryTitle2 from '../ViewAssets/CategoryTitles/CategoryTitle2/CategoryTitle2';

export default function ListAllCategories({ settings, imageType }) {
  const { organizationId, key, language} = useMyContext();
  const history = useHistory().location.pathname;
  const {title, catID} = useParams();
  const {
    // groupItemId,
    // slickSettings,
    routes,
    itemImageComponent,
    itemTitleComponent,
  } = settings;
  const [items, setItems] = useState(null);
  const [filterItems, setFilterItems] = useState([])

  const onSubmit = () => { }

  const handleChange = ([field]) => {
    // console.log(field.target.value);
    if (field.target.value === 'documentaries') {
      setFilterItems(items.filter(e => e.groups.includes('Documentaries')))
    }
    else if (field.target.value === 'comedy') {
      setFilterItems(items.filter(e => e.groups.includes('Comedy')))
    }
    else if (field.target.value === "editorchoice") {
      setFilterItems(items.filter(e => e.groups.includes("Editor's Choice")))
    }
    else if (field.target.value === "all") {
      setFilterItems(items)
    }
  };

  useEffect(() => {
    let token;
    const fetchLatestData = async () => {
      const res = await getLatestAssets(organizationId, token, language);
      setItems(res?.assets);
      setFilterItems(res?.assets)
    };

    const fetchTrendingData = async () => {
      const res = await getTrendingAssets(organizationId, token, language);
      const response = res && res.filter(e => {
        if(e.isSerie != true){
          return e
        }
      })
      setItems(response);
      setFilterItems(response)
    };

    const fetchAssets = async () => {
      let token = createToken(organizationId, catID, key);
      const res = await getAssets(organizationId, catID, token, language);
      console.log(res)
      setItems(res);
      setFilterItems(res)
    }


    if (organizationId && language) {
      token = createToken(organizationId, '', key);

      if (token) {
        if (history === "/AllShows/Trending") {
          fetchTrendingData();
        } else if (history === "/AllShows/Popular") {
          fetchLatestData();
        }else if(history === `/AllShows/categories/${title}/${catID}`){
          fetchAssets();
        }
      }
    }
  }, [history, key, language, organizationId]);

  console.log(filterItems)
  return (
    <div className='categoriesContainermain'>
      <div className={classes.categoryMain}>
        <Form
          onSubmit={onSubmit}
          mutators={{ handleChange }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.selectBox}>
                {/* <label style={{ color: "white" }}>Categories</label> */}
                {/* <Field name="categories">
                  {({ input, meta }) => (
                    <>
                      <select className={classes.categorySelect} onChange={form.mutators.handleChange}>
                        <option value="all">All</option>
                        <option value="documentaries">Documentaries</option>
                        <option value="comedy">Comedy</option>
                        <option value="editorchoice">Editor's Choice</option>
                      </select>
                    </>
                  )}
                </Field> */}
              </div>
            </form>
          )}
        >
        </Form>
        
        <CategoryTitle2 
          // id={'123456789098765421'}
          title={"Trending"}
          routes={routes}
          // item={null}
          showSerieViewAll={false}
          // isSerie={true}
          showTitle={true}
        />
        {filterItems && filterItems.length > 0 && (
          <div className={`${classes.ListAllCategories} font-500`}>
            {filterItems.map((item) => {
              let pushRoute = '';
              if (item.isSerie === true || item.series?.length > 0) {
                pushRoute = `/${routes.serieRoute}/${organizationId}/${item.id}`; 
              } else {
                pushRoute = `/${routes.videoRoute}/${organizationId}/${item.id}`;
              }

              return (
                <RenderItem
                  key={`${item.id}`}
                  item={item}
                  pushRoute={pushRoute}
                  className={classes.categoryItems}
                  routes={routes}
                  itemImageComponent={itemImageComponent}
                  itemTitleComponent={itemTitleComponent}
                  imageType={imageType}
                  textStyle={{ textAlign: 'center' }}
                  renderCategory={true}
                  showTitle={true}
                  showPlayIcon={true}
                  hidePlayButton={false}
                  hideDescription={true}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

                