// import { useHistory } from 'react-router-dom';
// import { getImageByKey } from '../../scripts/getImageByKey';
// import { useTranslation } from 'react-i18next';
import * as classes from './Footer.module.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaDribbble } from 'react-icons/fa'
import { FaFacebookF } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import googlePlayBadge from '../../images/google-play-badge.png'
import appleStoreBadge from '../../images/Available_on_the_App_Store_(black).png'

const BottomBar2 = (props) => {
  // const history = useHistory();

  // const { t } = useTranslation();

  return (
   <div className={classes.FooterSection}>
     <div className={`${classes.Footer} font-200`}>
      <div className={classes.Footer_Box}>
        {/* <div className={classes.Footer_Description}>
          <div className={classes.Footer_Description_Heading}>
            <img
              className='navBarSiteLogo'
              src={getImageByKey('siteLogo')}
              title={t('footer.hoverLogoBackToHome')}
              alt='SiteName'
              onClick={() => history.push('/')}
              style={props?.styles?.navBar}
            />
          </div>
        </div> */}
        <div className={classes.Footer_bottom_link}>
          <ul>
            <li><a href='https://www.mytvbroadcasting.my/'> ABOUT MYTV</a></li>
            <li><a href='/tos'> TERMS OF USE</a></li>
            <li><a href='/cookiesPolicy'> PRIVACY POLICY</a></li>
            <li><a href='/faq'> FAQ</a></li>
            <li><a href='/'> FEEDBACK</a></li>
          </ul>
        </div>
       <div className={classes.SocialContents}>
       <div className={classes.Footer_social_link}>
          <ul>
          <li className={classes.FooterContact}><a href='https://www.mytvbroadcasting.my/hubungi_kami/'> CONTACT WITH US</a></li>
            <li>
              <a href='https://www.mytvbroadcasting.my/'>
                <FaDribbble color='#b2b2b2'/>
              </a>
            </li>
            <li>
              <a href='https://www.facebook.com/mytvbroadcasting/'>
                <FaFacebookF color='#b2b2b2'/>
              </a>
            </li>
            <li>
              <a href='https://www.instagram.com/mytvbroadcasting_/'>
                <FaInstagram color='#b2b2b2'/>
              </a>
            </li>
          </ul>
        </div>
        <div className={classes.StoreContent}>
          <img className={classes.PlayStore} src={googlePlayBadge} alt="google-play-badge" />
          <img className={classes.AppStore} src={appleStoreBadge} alt="appleStoreBadge" />
        </div>
       </div>
        {/* <div className={classes.Footer_Description_Heading_Info}>
          Digital Tree is a OTT solution that provides сontent delivery platform
            and applications with cutting-edge features and business tools. If you
            want to create your own OTT Platform, visit
            https://staging1.digitaltree.icareus.com
          <br />
          {t('footer.copyRight')}
        </div> */}
      </div>
    </div>
    <div className={classes.navColorBar}>
        <div className={classes.navColorBar1}></div>
        <div className={classes.navColorBar2}></div>
      </div>
   </div>
  );
};

export default BottomBar2;
