import { useMyContext } from "../../contexts/StateHolder";
// import DetailsFolderNames from './DetailsFolderNames';
import DetailsContentRatings from "./DetailsContentRatings";
import DetailsInfoData from "./DetailsInfoData";
import DetailsItemDescription from "./DetailsItemDescription";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
    convertDuration,
    createAssetIdToken,
    createToken,
    // createAssetIdToken,
    // createToken,
    removePunctuation,
} from "../../scripts/utils";
// import { getAsset, getSubCategories } from '../../scripts/dataHandlers';
import DetailsAssetBanner from "./DetailsAssetBanner";
import DetailsShare from "./DetailsShare";
import * as classes from "./DetailsVideo.module.css";
import Loader from "react-loader-spinner";
import axios from "axios";
import moment from "moment";
import { getAsset, getSubCategories } from "../../scripts/dataHandlers";
// import HelmetMetaData from '../ShareSocialMedia/HelmetMetaData';
const DetailsVideo = (props) => {
    // Bring stateholders from context
    const { chosenItem, setChosenItem, language, key, organizationId, user, loading, setLoading } = useMyContext();

    const { asset } = useParams();
    const year = new Date(chosenItem?.date).getFullYear();

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        async function fetchAsset() {
            setChosenItem(null);
            setLoading(true);
            try {
                // Call createToken function to create new token from given data
                let token = createAssetIdToken(organizationId, asset, language, key);

                const assetResponse = await getAsset(
                    organizationId,
                    asset,
                    token,
                    language,
                    undefined, // TODO: MOVE TO COMPONENT CONFIG
                    user,
                    source
                );
                console.log(`assetResponse`, assetResponse);

                setChosenItem(assetResponse);
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }

        const fetchSerieCategoryAsset = async () => {
            setChosenItem(null);
            setLoading(true);

            try {
                let token3 = createToken(organizationId, asset, key);

                const response3 = await getSubCategories(organizationId, token3, asset, language, user, source);
                console.log(`response3`, response3);

                setChosenItem(response3[0]);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        if (language) {
            props.isSerieCategory ? fetchSerieCategoryAsset() : fetchAsset();
        }

        return () => source.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset, key, language, organizationId, props.isSerieCategory, user]);
    const descriptionTitle = props.isSerie
        ? chosenItem && `${chosenItem.series[1].name} / Total ${props.seasonCount} Episodes`
        : `|  ${convertDuration(chosenItem?.duration)}  |   VIP ACCESS`;

    const renderDetails = () => {
        return (
            <div className="detailsContainer">
                {!props.hideBanner && chosenItem?.bannerImageSmall ? <DetailsAssetBanner routes={props.routes} hideCta={props.hideCta} /> : null}

                <div className={classes.details_description_container}>
                    <div className={classes.HeroContents}>
                        <div className={classes.LeftContent}>
                            <div className={classes.detailsDescriptionTitleContainer}>
                                <div className={classes.details_description_title_left}>
                                    {/* Series Name */}
                                    {chosenItem.series && <h5 style={{ fontSize: "14px", marginBottom: "5px" }}>{chosenItem?.series[0]?.name}</h5>}
                                    <div className={`${classes.details_description_title_name} font-600`}>
                                        {`${removePunctuation(chosenItem?.name ? chosenItem.name : chosenItem.description.en_US.name)}`}{" "}
                                        {props.isSerie && `- Episode ${props.episodeNumber}`}
                                    </div>
                                    <p>{chosenItem.serviceId && chosenItem.description.en_US.longDescription}</p>
                                </div>

                                {chosenItem.contentRatings?.length > 0 && (
                                    <div className={classes.details_description_title_right}>
                                        <DetailsContentRatings />{" "}
                                    </div>
                                )}
                            </div>
                            <div className="details-description-info-container">
                                <div className="detailsDescriptionContainer"></div>
                                {!props.hideInfoData ? (
                                    <div className="detailsInfoContainer">
                                        <DetailsInfoData item={chosenItem} />
                                    </div>
                                ) : null}
                            </div>

                            {!props.isCatchUpPlay && (
                                <div className={classes.HeroText}>
                                    {chosenItem?.folders.map((el1, i) => (
                                        <h6 key={i.toString()}>{el1.name}</h6>
                                    ))}

                                    <h6>{year}</h6>
                                </div>
                            )}
                        </div>
                        <div className={classes.RightContent}>
                            {chosenItem.date && <h4> {descriptionTitle}</h4>}
                            {!props.isTVGuideItem ? null : <div dangerouslySetInnerHTML={{ __html: chosenItem.description }} />}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // TODO: If /detailsVideo, take parameters and render loading while fetching data or something like that
    return chosenItem
        ? renderDetails()
        : !chosenItem && loading && (
              <div className="display-flex-center">
                  <Loader type="TailSpin" color="#dfdfdf" height={50} width={50} />
              </div>
          );
};

export default DetailsVideo;
