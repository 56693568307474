import { useMyContext } from "../../contexts/StateHolder";
import { useEffect, useState } from "react";
import settings from "../../configs/config_settings.json";
import { useCookies } from "react-cookie";
import { useMyContextFunctions } from "../../contexts/ContextFunctions";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import * as classes from "./TVGuide.module.css";
import TVGuideChannelData from "./TVGuideChannelData";


export default function TVGuide(props) {
  // Bring stateholders from context
  const { key, organizationId, liveChannelPrivate } = useMyContext();

  const [channelServiceId, setChannelServiceId] = useState(null);
  // const [groupId, setGroupId] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const { redirectUserToLogin } = useMyContextFunctions();
  const [cookies] = useCookies("");
  // console.log(cookies);

  useEffect(() => {
    if (organizationId) {
      // setGroupId(settings.organization.groupId);
      setChannelServiceId(settings.organization.channelServiceId);
      setCompanyId(settings.organization.companyId);
    }
  }, [organizationId]);
  
  useEffect(() => {
    if (liveChannelPrivate) {
      !cookies.userData.userId && redirectUserToLogin("login");
    }
  }, [redirectUserToLogin, cookies.userData, liveChannelPrivate]);

  return key && channelServiceId && companyId ? (
    <div className="maxContainerPrimary">
      <TVGuideChannelData />
    </div>
  ) : null;
}
