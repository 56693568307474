import React, { useEffect, useState } from "react";
import { useMyContext } from "../../../../../contexts/StateHolder";
import { getChannels } from "../../../../../scripts/dataHandlers";
// import { createToken } from "../../../../../scripts/utils";
import CategoryTitle from "../../../CategoryTitles/CategoryTitle";
import CategoryTitle2 from "../../../CategoryTitles/CategoryTitle2/CategoryTitle2";
import useWindowDimensions from "../../../../WindowDimension";
import Carousel from "react-multi-carousel";
import * as classes from './LiveChannels.module.css';
import RenderItem from "../../../RenderItems/RenderItem";

const components = {
    CategoryTitle,
    CategoryTitle2,
};

export default function LiveChannels(props) {
    const { organizationId, key, language, setOrganizationId, } = useMyContext();
    // Destructure props.settings
    const { id, routes, slickSettings, categoryTitleComponent, itemImageComponent, itemTitleComponent, maintainImageAspectRatio, days, limit } = props.settings;

    const placeholderItem = {
        id: "123456789087654321",
        name: "placeholderItem",
    };

    const placeHolderArray = [placeholderItem, placeholderItem, placeholderItem, placeholderItem, placeholderItem, placeholderItem, placeholderItem];

    const [items, setItems] = useState(null);

    const windowDimension = useWindowDimensions();

    useEffect(() => {
        // let token;
        // const fetchLatestData = async () => {
        //   const res = await getLatestAssets(organizationId, token, language, limit);
        //   setItems(res?.assets);
        // };

        // const fetchPopularData = async () => {
        //   const res = await getTrendingAssets(organizationId, token, language, limit, days);
        //   setItems(res);
        // };

        const fetchLiveChannelData = async () => {
            setOrganizationId(196288014);
            const res = await getChannels(196288014);
            // console.log("all Channels=>", res?.data.channels);
            setItems(res?.data.channels);
        };

        fetchLiveChannelData();

        // if (organizationId && language) {
        //   token = createToken(organizationId, '', key);

        //   if (token) {
        //     props.latestData ? fetchLatestData() : fetchPopularData();
        //   }
        // }
    }, [language, organizationId, key, props.latestData, days, limit, setOrganizationId]);
    let className1 = "";

    const renderItems = () => {
        const TitleComponent = components[categoryTitleComponent];

        if (items?.length > 0 && slickSettings) {
            let arr = Object.keys(slickSettings.responsive).map((k) => slickSettings.responsive[k]);
            let arr1 = [...arr];

            let j = [...arr1].find((el) => el.breakpoint.min <= windowDimension.width && el.breakpoint.max >= windowDimension.width);

            if (items?.length === j.items) {
                className1 = "addSeecondary";
            } else {
                className1 = "";
            }
        }

        return (
            <div className={`${className1} ${classes.LiveChannelsContainer}`}>
                <TitleComponent
                    id={"123456789098765421"}
                    title={props.titleName}
                    routes={routes}
                    item={null}
                    showSerieViewAll={false}
                    isSerie={true}
                    showTitle={true}
                />
                {items && items.length > 0 ? (
                    <div className={classes.liveSlider}>
                        <Carousel {...slickSettings}>
                            {items.map((item, i) => {
                                return (
                                    <RenderItem
                                        key={`${id} ${i}`}
                                        item={item}
                                        pushRoute={`/${routes.liveChannels}/${organizationId}/${item.serviceId}`}
                                        //   playOnClick={false}
                                        itemImageComponent={itemImageComponent}
                                        imageType={"thumbnail"}
                                        imageChannel={true}
                                        toggleVideoPlayer={true}
                                        itemTitleComponent={itemTitleComponent}
                                        showTitle={props.showTitle}
                                        serviceId={item.serviceId}
                                        showOnNowDetails={props.showOnNowDetails}
                                        liveChannel="liveChannel"
                                    />
                                );
                            })}
                        </Carousel>
                    </div>
                ) : (

                    <Carousel {...slickSettings}>
                        {placeHolderArray.map((item, i) => {
                            return (
                                <RenderItem
                                    key={`${id} ${i}`}
                                    item={item}
                                    routes={routes}
                                    playOnClick={false}
                                    itemImageComponent={itemImageComponent}
                                    showCategoryName={false}
                                    showPlayIcon={false}
                                    // whether to maintain aspect ratio 16/9
                                    maintainImageAspectRatio={maintainImageAspectRatio}
                                    isSerie={false}
                                />
                            );
                        })}
                    </Carousel>

                )}
                <span></span>
            </div>
        );
    };
    return renderItems();
}
