import React, { useState, useEffect } from 'react';
import Banner from './Banner'
import { useMyContext } from '../../contexts/StateHolder';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import { createToken } from '../../scripts/utils';
import { getTrendingShow } from '../../scripts/dataHandlers';
import DetailsSeasonsAndEpisodes from '../Details/DetailsSeasonsAndEpisodes'
import settings from '../../configs/config_settings.json'
import * as classes from './Banner.module.css';

const AllShowsListingPage = () => {
    const {seriesId} = useParams();
    const { organizationId, key, language, user } = useMyContext();
    const [item, setItem] = useState(null)

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        
        try{
            const getData = async () => {
                const token = createToken(organizationId, '', key);
            
                const res = await getTrendingShow(organizationId, token, 30, 10);
                
                res && res.map(e => {
                    if(e.id == seriesId) setItem(e)
                })
                // console.log(res)
            };

            organizationId && key && getData();
        }catch(e){
            console.log(e)
        }
    
        return () => source.cancel();
    }, [language, user, seriesId]);

    return (
        <>
            {item && <Banner item={item}/>}
            <div>
                <h1 className={classes.title} >{item && item.title}</h1>
            </div>
            <DetailsSeasonsAndEpisodes
                playVideoRoute={settings.routes.playSerie}
                showNowPlaying={seriesId}
            />
        </>
    )   
}

export default AllShowsListingPage